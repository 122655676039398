import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ArrowRight from '@/presentation/assets/svg/ArrowRight.svg'
import CircleInfo from '@/presentation/assets/svg/CircleInfo'
import Header from '@/presentation/components/Header'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useDivergence } from '@/services/providers/DivergenceContext'
import { useRoom } from '@/services/providers/RoomContext'

const SentItemDetailsPage: React.FC = () => {
  const navigate = useNavigateWithOrderCode()

  const { t } = useTranslation()

  const { divergences } = useDivergence()
  const { rooms } = useRoom()

  const { roomId, itemId } = useParams()

  const room = rooms.find(room => room?.id === roomId)
  const item = room?.items.find(item => item?.id === itemId)
  const divergenceItem = divergences.find(divergence => {
    return divergence?.roomId === roomId && divergence?.itemId === itemId
  })

  useEffect(() => {
    if (!divergenceItem || !item || !room) navigate(ROUTES.DIVERGENCE_LIST)
  }, [divergenceItem, item, navigate, room])

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={ROUTES.DIVERGENCE_LIST}
        title={`${item?.name} | ${room?.name}`}
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <span className="block mb-2 w-full text-left text-gray-500">
          {t('CONTESTED_ITEM', 'Item contestado')}
        </span>

        <div
          className="flex flex-col mb-6 w-full bg-white rounded-xl border border-gray-400 shadow-md cursor-pointer"
          onClick={() => navigate(`${ROUTES.ROOM_ITEM}/${roomId}/${itemId}`)}
        >
          <div
            className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
            style={{ backgroundImage: `url(${item?.image})` }}
          />

          <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left">
            <span className="text-xl font-semibold text-gray-600">
              {item?.id === 'building'
                ? item?.name
                : `${item?.name} | ${room?.name}`}
            </span>

            {item?.observation && (
              <span className="text-sm text-gray-500">{item?.observation}</span>
            )}
          </div>
        </div>

        {divergenceItem?.description && (
          <>
            <span className="block mb-4 w-full text-left text-gray-500">
              {t('CONTESTATION_MENU', 'Contestação')}
            </span>

            <p className="mb-6 w-full text-sm text-left text-gray-500">
              {divergenceItem.description}
            </p>
          </>
        )}

        {!!divergenceItem?.photos?.length && (
          <>
            <span className="block mb-4 w-full text-left text-gray-500">
              {t('PHOTOS', 'Fotos')}
            </span>

            <div className="flex flex-wrap gap-4 justify-start items-center mb-6 w-full">
              {divergenceItem.photos.map(photo => (
                <div
                  key={photo?.filePath}
                  className={`
                    relative
                    bg-no-repeat
                    bg-center
                    w-[72px]
                    h-[72px]
                    rounded-lg
                    border-[1.5px]
                    border-primary
                    ${photo?.filePath ? 'bg-cover' : 'bg-white'}
                  `}
                  style={{ backgroundImage: `url("${photo?.filePath}")` }}
                />
              ))}
            </div>
          </>
        )}

        <div className="mb-4 w-full">
          <div className="flex gap-2 items-center mb-2">
            <CircleInfo color="#6b7280" className="shrink-0" />

            <span className="text-gray-500">
              {t(
                'CHECK_COMPLETE_REPORT_FOR_MORE_DETAILS',
                'Confira o relatório completo para mais detalhes'
              )}
            </span>
          </div>

          <div
            className="flex gap-x-2 justify-center items-center p-4 w-full max-w-sm text-primary bg-[#FAFAFA] rounded-xl border border-gray-300 shadow-sm cursor-pointer"
            onClick={() => navigate(ROUTES.DIVERGENCE_LIST)}
          >
            <span className="flex-1 text-center">
              {t('SEE_CONTESTATIONS_AGAIN', 'Visualizar contestações')}
            </span>

            <img src={ArrowRight} alt="Arrow right icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SentItemDetailsPage
