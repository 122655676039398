import { useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import sendEvaluate from '@/infra/http/sendEvaluate'
import CircleInfo from '@/presentation/assets/svg/CircleInfo'
import EvaluateBackground from '@/presentation/assets/svg/EvaluateBackground.svg'
import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import KnowMoreModal from '@/presentation/pages/EvaluatePage/components/KnowMorePopup'
import StarRating from '@/presentation/pages/EvaluatePage/components/StarRating'
import SuccessModal from '@/presentation/pages/EvaluatePage/components/SuccessModal'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useInspection } from '@/services/providers/InspectionContext'
import { useUser } from '@/services/providers/UserContext'

const EvaluatePage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigateWithOrderCode()

  const { user } = useUser()
  const { inspection } = useInspection()

  const commentInputRef = useRef<HTMLTextAreaElement>(null)

  const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [inspector, setInspector] = useState(0)
  const [report, setReport] = useState(0)

  const handleChangeReportRating = (rating: number) => {
    if (rating === report && rating > 1) {
      setReport(rating - 1)

      return
    }

    setReport(rating)
  }

  const handleChangeInspectorRating = (rating: number) => {
    if (rating === inspector && rating > 1) {
      setInspector(rating - 1)

      return
    }

    setInspector(rating)
  }

  const handleConfirmForm = async () => {
    if (!user) {
      navigate(ROUTES.REGISTER_INITIAL)

      return
    }

    if (!inspection) {
      navigate(ROUTES.MAIN)

      return
    }
    const payload = {
      rating: {
        inspector,
        report,
        comment: commentInputRef.current?.value || ''
      },
      creator: {
        name: user.name,
        email: user.email,
        personType: user.personType
      }
    }

    await sendEvaluate(inspection.publicId, payload)

    setShowSuccessModal(true)

    setTimeout(() => {
      navigate(ROUTES.MAIN)
    }, 2000)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title={t('EVALUATE_YOUR_INSPECTION', 'Avalie sua vistoria')} />

      <div className="flex relative flex-col items-center py-2 px-4 sm:w-96 md:w-144">
        <span className="block mb-4 w-full text-xl text-left text-[#72767D]">
          {t('REPORT', 'Relatório')}
        </span>

        <StarRating rating={report} handleChange={handleChangeReportRating} />

        <span className="block mb-4 w-full text-xl text-left text-[#72767D]">
          {t('INSPECTOR', 'Vistoriador')}
        </span>

        <StarRating
          rating={inspector}
          handleChange={handleChangeInspectorRating}
        />

        <div className="mb-4" />

        <span className="flex gap-4 items-center mb-4 w-full text-xl text-left text-[#72767D]">
          {t('ADD_COMMENT', 'Adicionar comentário')}{' '}
          <span className="text-sm font-medium text-gray-400">
            ({t('OPTIONAL', 'Opcional')})
          </span>
        </span>

        <textarea
          className="p-2 mb-6 w-full min-h-[82px] text-sm rounded-lg border border-gray-300 focus:border-primary focus:outline-none shadow-md"
          placeholder={t('DESCRIBE_EXPERIENCE', 'Descreva sua experiência...')}
          ref={commentInputRef}
        />

        <div className="mb-20 w-full text-sm text-left text-[#72767D]">
          {t(
            'THIS_FIELD_IS_EXCLUSIVELY_FOR_EVALUATING_YOUR_EXPERIENCE',
            'Esse campo é destinado exclusivamente para avaliar sua experiência.'
          )}{' '}
          <span
            onClick={() => setShowKnowMoreModal(true)}
            className="flex items-center mt-2 text-primary cursor-pointer"
          >
            <CircleInfo className="mx-1 cursor-pointer" />

            <span>{t('LEARN_MORE', 'Saiba mais')}</span>
          </span>
        </div>

        <Button
          className="mb-4 w-full max-w-[420px]"
          onClick={handleConfirmForm}
        >
          {t('EVALUATE', 'Avaliar')}
        </Button>

        <img
          className="absolute -right-80 bottom-0 -z-10 max-w-[400px]"
          src={EvaluateBackground}
          alt="Evaluate inspection"
        />
      </div>

      {showSuccessModal && <SuccessModal />}

      {showKnowMoreModal && (
        <KnowMoreModal
          inspectionType={inspection?.inspectionType}
          onCancel={() => setShowKnowMoreModal(false)}
        />
      )}
    </div>
  )
}

export default EvaluatePage
