import { useTranslation } from 'react-i18next'

interface TitleProps {
  inspectionType: string
}

const Title: React.FC<TitleProps> = ({ inspectionType }) => {
  const { t } = useTranslation()

  return (
    <h1 className="flex flex-col mb-8 w-full text-left">
      <span className="text-4xl text-gray-600">
        {t('WELCOME_TO', 'Bem-vindo ao')}
      </span>

      <span className="-mt-1 text-4xl font-bold text-primary">
        {inspectionType === 'predial'
          ? t('MY_DIGITAL_CONDOMINIUM', 'Meu Condomínio digital')
          : t('MY_DIGITAL_PROPERTY', 'Meu Imóvel digital')}
      </span>
    </h1>
  )
}

export default Title
