import { Fragment, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

const showTranslatorPathnames = ['main', 'onboarding', 'register/initial']

const TranslatorObserver: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const showTranslator = !!showTranslatorPathnames.find(path =>
      pathname.includes(path)
    )

    const languageSelector: HTMLDivElement | null = document.querySelector(
      '#language-selector-wrapper'
    )

    if (showTranslator) {
      languageSelector && (languageSelector.style.display = 'block')
    } else {
      languageSelector && (languageSelector.style.display = 'none')
    }
  }, [pathname])

  return <Fragment />
}

export default TranslatorObserver
