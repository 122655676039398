import { useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import sendEvaluate from '@/infra/http/sendEvaluate'
import SuccessModalIcon from '@/presentation/assets/svg/SuccessModalIcon.svg'
import X from '@/presentation/assets/svg/X.svg'
import Button from '@/presentation/components/Button'
import StarRating from '@/presentation/pages/EvaluatePage/components/StarRating'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useInspection } from '@/services/providers/InspectionContext'
import { useRoom } from '@/services/providers/RoomContext'
import { useUser } from '@/services/providers/UserContext'

interface RatingPopupProps {
  onCancel?: () => void
  redirectRouteAfterSubmit: ROUTES
}

const SuccessPopup: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]">
      <div className="flex flex-col gap-4 items-center p-8 bg-white rounded-lg shadow-md">
        <img src={SuccessModalIcon} className="w-24 h-24" />

        <span className="text-lg font-bold text-[#4F6066]">
          {t('EVALUATION_SENT', 'Avaliação enviada!')}
        </span>
      </div>
    </div>
  )
}

const RatingPopup: React.FC<RatingPopupProps> = ({
  onCancel,
  redirectRouteAfterSubmit
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [inspectorRating, setInspectorRating] = useState(0)
  const [reportRating, setReportRating] = useState(0)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)

  const commentInputRef = useRef<HTMLTextAreaElement>(null)

  const { t } = useTranslation()

  const { inspection, setCloseRatingInspectionPopupForever } = useInspection()
  const { setSelectedRoomsIds } = useRoom()
  const { user } = useUser()

  const navigate = useNavigateWithOrderCode()

  const handleClose = () => {
    setSelectedRoomsIds(new Set([]))
    setCloseRatingInspectionPopupForever(true)
    onCancel && onCancel()
  }

  const handleChangeReportRating = (rating: number) => {
    if (rating === reportRating && rating > 1) {
      setReportRating(rating - 1)

      return
    }

    setReportRating(rating)
  }

  const handleChangeInspectorRating = (rating: number) => {
    if (rating === inspectorRating && rating > 1) {
      setInspectorRating(rating - 1)

      return
    }

    setInspectorRating(rating)
  }

  const handleConfirmForm = async () => {
    if (!user) {
      navigate(ROUTES.REGISTER_INITIAL)

      return
    }

    if (!inspection) {
      navigate(ROUTES.MAIN)

      return
    }

    const evaluateData = {
      rating: {
        inspector: inspectorRating,
        report: reportRating,
        comment: commentInputRef.current?.value || ''
      },
      creator: {
        name: user.name,
        email: user.email,
        personType: user.personType
      }
    }

    await sendEvaluate(inspection.publicId, evaluateData)

    setShowSuccessPopup(true)

    setTimeout(() => {
      handleClose()
      navigate(redirectRouteAfterSubmit)
    }, 2000)
  }

  const firstStep = (
    <>
      <span className="block mb-4 text-gray-700">
        {t(
          'HELP_US_IMPROVE',
          'Ajude-nos a melhorar! Nos conte um pouco sobre sua experiência com a vistoria e relatório.'
        )}
      </span>

      <span className="mb-4 text-gray-700">
        {t(
          'EVALUATE_LATER_OPTION',
          'Caso prefira fazer isso depois, você pode encontrar a página de avaliação no menu inicial.'
        )}
      </span>

      <Button className="mb-4 w-full" onClick={() => setCurrentStep(1)} colored>
        {t('EVALUATE_NOW', 'Avaliar agora')}
      </Button>

      <Button
        className="w-full"
        styleType="outline"
        onClick={handleClose}
        colored
      >
        {t('SEE_LATER', 'Ver depois')}
      </Button>
    </>
  )

  const secondStep = (
    <>
      <span className="block mb-4 text-gray-700">
        {t(
          'HELP_US_IMPROVE',
          'Ajude-nos a melhorar! Nos conte um pouco sobre sua experiência com a vistoria e relatório.'
        )}
      </span>

      <span className="block mb-4 w-full text-left text-[#72767D]">
        {t('REPORT', 'Relatório')}
      </span>

      <StarRating
        rating={reportRating}
        handleChange={handleChangeReportRating}
      />

      <span className="block mb-4 w-full text-left text-[#72767D]">
        {t('INSPECTOR', 'Vistoriador')}
      </span>

      <StarRating
        rating={inspectorRating}
        handleChange={handleChangeInspectorRating}
      />

      <div className="mb-4" />

      <span className="flex gap-4 items-center mb-4 w-full text-left text-[#72767D]">
        {t('ADD_COMMENT', 'Adicionar comentário')}{' '}
        <span className="text-sm font-medium text-gray-400">
          ({t('OPTIONAL', 'Opcional')})
        </span>
      </span>

      <textarea
        className="p-2 mb-6 w-full min-h-[82px] text-sm rounded-lg border border-gray-300 focus:border-primary focus:outline-none shadow-md"
        placeholder={t('DESCRIBE_EXPERIENCE', 'Descreva sua experiência...')}
        ref={commentInputRef}
      />

      <Button className="w-full" onClick={handleConfirmForm} colored>
        {t('SEND_EVALUATION', 'Enviar avaliação')}
      </Button>
    </>
  )

  const steps = [firstStep, secondStep]

  if (showSuccessPopup) return <SuccessPopup />

  return (
    <div
      onClick={handleClose}
      className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]"
    >
      <div
        className="flex flex-col p-4 px-6 max-w-[460px] bg-white rounded-lg"
        onClick={event => event.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-1">
          <span className="text-lg text-gray-700">
            {t('EVALUATE_YOUR_INSPECTION', 'Avalie sua vistoria')}!
          </span>

          <img src={X} onClick={handleClose} className="cursor-pointer" />
        </div>

        <hr className="mb-4" />

        {steps[currentStep]}
      </div>
    </div>
  )
}

export default RatingPopup
