import { SVGProps } from 'react'

const Edit: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M14.6654 6.66667H7.9987C6.52594 6.66667 5.33203 7.86058 5.33203 9.33334V24C5.33203 25.4728 6.52594 26.6667 7.9987 26.6667H22.6654C24.1381 26.6667 25.332 25.4728 25.332 24V17.3333M23.4464 4.78105C24.4878 3.73965 26.1762 3.73965 27.2176 4.78105C28.259 5.82245 28.259 7.51089 27.2176 8.55228L15.7699 20H11.9987L11.9987 16.2288L23.4464 4.78105Z"
        stroke={props.color || '#0170B3'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Edit
