import { useTranslation } from 'react-i18next'

import ErrorIcon from '@/presentation/assets/svg/ErrorIcon.svg'
import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'

const AlterationErrorPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()

  const handleHomeClick = () => navigate(ROUTES.MAIN)

  const handleAlterations = () => navigate(ROUTES.ALTERATION_LIST)

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title={t('REGISTER_CHANGES', 'Registrar alterações')} />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <img src={ErrorIcon} className="mb-6 w-36" />

        <span className="block mb-2 text-xl font-bold text-center text-error">
          {t(
            'UNEXPECTED_ERROR',
            'Um erro inesperado ocorreu ao enviar suas alterações.'
          )}
        </span>

        <span className="block mb-12 font-bold text-center text-gray-500">
          {t('TRY_AGAIN_LATER', 'Tente novamente mais tarde.')}
        </span>

        <Button className="mb-4 w-full max-w-sm" onClick={handleAlterations}>
          {t('SEE_CHANGES', 'Ver alterações')}
        </Button>

        <Button
          className="w-full max-w-sm"
          styleType="outline"
          onClick={handleHomeClick}
          colored
        >
          {t('START', 'Início')}
        </Button>
      </div>
    </div>
  )
}

export default AlterationErrorPage
