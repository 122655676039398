import api from '@/services/api'
import { Divergence } from '@/services/providers/DivergenceContext'

export const getInspectionDivergences = async (orderCode: string) => {
  try {
    const response = await api.get(`/public/hotsite/${orderCode}/divergences`)

    const divergences = Object.entries(response.data?.divergences).map(
      ([id, divergence]: [string, any]) => {
        let roomId = ''
        let itemId = id

        if (itemId === '0') {
          roomId = '0'
          itemId = 'building'
        } else if (!itemId?.includes('.')) {
          roomId = itemId
          itemId = 'general'
        } else {
          roomId = itemId?.split('.')[0]
        }

        return {
          itemId,
          roomId,
          description: divergence?.description || '',
          photos: divergence?.photos || [],
          extra: {
            name: divergence?.additionalData?.name || '',
            parentName: divergence?.additionalData?.parentName || ''
          }
        } as Divergence
      }
    )

    return Array.isArray(divergences) ? divergences : []
  } catch {
    return []
  }
}
