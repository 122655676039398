import { useTranslation } from 'react-i18next'

import rvLogo from '@/presentation/assets/svg/RVLogo.svg'

const InspectionNotFoundPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <section className="flex justify-start items-start p-2 w-full h-full bg-white">
      <div className="mt-24 w-full text-center">
        <img
          className="mx-auto mb-16 w-72"
          src={rvLogo}
          alt="Grupo RV logo"
          title="Grupo RV"
        />

        <span className="text-xl font-semibold text-primary">
          404
          <br />
          {t('REQUEST_NOT_FOUND', 'Pedido não encontrado')}
        </span>
      </div>
    </section>
  )
}

export default InspectionNotFoundPage
