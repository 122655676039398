import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import CircleCheck from '@/presentation/assets/svg/CircleCheck'
import Photo from '@/presentation/assets/svg/Photo.svg'
import RemoveDivergencePhoto from '@/presentation/assets/svg/RemoveDivergencePhoto.svg'
import ProgressSpinner from '@/presentation/components/ProgressSpinner'

interface FormImageProps {
  image?: File | string
  uploadProgress: number
  handleClickWithoutImage: () => void
  handleClickWithImage: () => void
  hasRemotelyUploaded: boolean
}

const FormImage: React.FC<FormImageProps> = ({
  image,
  uploadProgress,
  handleClickWithImage,
  handleClickWithoutImage,
  hasRemotelyUploaded
}) => {
  const { t } = useTranslation()

  const previewPhoto = useMemo(() => {
    if (!image) return Photo

    if (typeof image === 'string') return image || Photo

    return URL.createObjectURL(image as File)
  }, [image])

  return (
    <div
      className={`bg-no-repeat bg-center w-[72px] h-[72px] rounded-lg border-[1.5px] border-primary cursor-pointer relative ${
        image ? 'bg-cover' : 'bg-white'
      }`}
      style={{ backgroundImage: `url("${previewPhoto}")` }}
      onClick={() => !image && handleClickWithoutImage()}
    >
      {uploadProgress > 0 && uploadProgress < 100 && (
        <ProgressSpinner progress={uploadProgress} />
      )}

      {(uploadProgress === 100 || hasRemotelyUploaded) && (
        <CircleCheck
          className="absolute top-[19px] right-[19px]"
          width={32}
          height={32}
          color="#0170B3"
        />
      )}

      {image && (
        <img
          className="absolute -top-2 -right-2"
          src={RemoveDivergencePhoto}
          alt="Remove photo icon"
          title={t('REMOVE_PHOTO', 'Remover foto')}
          onClick={() => handleClickWithImage()}
        />
      )}
    </div>
  )
}

export default FormImage
