import { useEffect } from 'react'
import { useRef } from 'react'
import { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import { useLocation, useParams } from 'react-router-dom'

import ArrowRight from '@/presentation/assets/svg/ArrowRight.svg'
import CircleInfo from '@/presentation/assets/svg/CircleInfo'
import Button from '@/presentation/components/Button'
import Carousel from '@/presentation/components/Carousel'
import ImageItem from '@/presentation/components/Carousel/components/ImageItem'
import VideoItem from '@/presentation/components/Carousel/components/VideoItem'
import CircleStatus from '@/presentation/components/CircleStatus'
import FullscreenViewer from '@/presentation/components/FullscreenViewer'
import Header from '@/presentation/components/Header'
import RoomItem from '@/presentation/components/RoomItem'
import { divergenceOnboardingStorageKey } from '@/services/constants/localStorageKeys'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode, {
  ILocationState
} from '@/services/hooks/useNavigateWithOrderCode'
import { useAlteration } from '@/services/providers/AlterationContext'
import { Status, useDivergence } from '@/services/providers/DivergenceContext'
import {
  InspectionType,
  useInspection
} from '@/services/providers/InspectionContext'
import { Photo, useRoom, Video } from '@/services/providers/RoomContext'
import checkLocalStorageKeyAndDo from '@/services/utils/checkLocalStorageKeyAndDo'

const GeneralRoomItemDetailsPage: React.FC = () => {
  const [showFullscreenViewer, setShowFullscreenViewer] = useState(false)
  const [fullscreenViewerIndex, setFullscreenViewerIndex] = useState(0)
  const [fullscreenViewerPhotos, setFullscreenViewerPhotos] = useState<Photo[]>(
    []
  )
  const [fullscreenViewerVideos, setFullscreenViewerVideos] = useState<Video[]>(
    []
  )

  const carouselRef = useRef<ResponsiveCarousel>(null)

  const { orderCode, roomId } = useParams()
  const { pathname } = useLocation()

  const { state } = useLocation()
  const { alteration } = state as ILocationState

  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()

  const { divergences, status, startNewDivergence } = useDivergence()
  const { startNewAlteration } = useAlteration()
  const { handlePutVisitedLabelInRoomItem, rooms } = useRoom()
  const { inspection } = useInspection()

  const room = rooms.find(room => room?.id === roomId)

  const useDamages =
    inspection?.inspectionType === InspectionType.SAIDA ||
    inspection?.inspectionType === InspectionType.CONFERENCIA

  const carouselPhotos = useMemo(() => {
    if (!room?.photos?.length) return []

    return room?.photos.map(photo => (
      <ImageItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [room?.photos])

  const carouselDamagePhotos = useMemo(() => {
    if (!room?.damagesPhotos?.length) return []

    return room?.damagesPhotos.map(photo => (
      <ImageItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [room?.damagesPhotos])

  const carouselVideos = useMemo(() => {
    if (!room?.videos?.length) return []

    return room?.videos.map(video => (
      <VideoItem
        key={video?.filePath}
        filePath={video?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [room?.videos])

  const carouselDamageVideos = useMemo(() => {
    if (!room?.videos?.length) return []

    return room?.videos.map(video => (
      <VideoItem
        key={video?.filePath}
        filePath={video?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [room?.damagesVideos])

  const handleOpenFullscreenViewer = (index: number) => {
    const rootElement = document.getElementById('root')

    if (rootElement) {
      rootElement.style.filter = 'blur(4px)'
      rootElement.style.position = 'absolute'
      rootElement.style.inset = '0'
    }

    setFullscreenViewerPhotos(
      useDamages ? room?.damagesPhotos || [] : room?.photos || []
    )
    setFullscreenViewerVideos(() => {
      const videos = useDamages ? room?.damagesVideos || [] : room?.videos || []

      return videos.map(video => ({ ...video, isVideo: true }))
    })
    setFullscreenViewerIndex(index)
    setShowFullscreenViewer(true)
  }

  const handleCloseFullscreenViewer = () => {
    const rootElement = document.getElementById('root')

    if (rootElement) {
      rootElement.style.filter = ''
      rootElement.style.position = ''
      rootElement.style.inset = ''
    }

    setShowFullscreenViewer(false)
  }

  const handleNewDivergence = () => {
    room && startNewDivergence(room?.id, 'general')

    if (
      orderCode &&
      checkLocalStorageKeyAndDo(divergenceOnboardingStorageKey(orderCode))
    ) {
      navigate(ROUTES.DIVERGENCE_FORM)

      return
    }

    navigate(ROUTES.DIVERGENCE_TUTORIAL)
  }

  const handleNewAlteration = () => {
    room && startNewAlteration(room?.id, 'general')

    navigate(ROUTES.ALTERATION_FORM)
  }

  const handleGoToDivergences = () => navigate(ROUTES.DIVERGENCE_LIST)

  useEffect(() => {
    carouselRef.current?.moveTo(0)

    return () => {
      handleCloseFullscreenViewer()
    }
  }, [pathname])

  useEffect(() => {
    if (!orderCode || !room?.id) return

    handlePutVisitedLabelInRoomItem(orderCode, room?.id, 'general')
  }, [orderCode, room?.id])

  const carouselContent = useDamages
    ? [...carouselDamageVideos, ...carouselDamagePhotos]
    : [...carouselVideos, ...carouselPhotos]

  const fullscreenViewerContent = [
    ...fullscreenViewerVideos,
    ...fullscreenViewerPhotos
  ]

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={ROUTES.ROOMS}
        title={`${t('GENERAL', 'Geral')} | ${room?.name}`}
      />

      <div className="px-4 w-full max-w-[1304px]">
        <div className="flex flex-wrap gap-x-12 justify-between items-start my-6 lg:flex-nowrap">
          <Carousel
            handleCarouselItemClick={handleOpenFullscreenViewer}
            ref={carouselRef}
          >
            {carouselContent}
          </Carousel>

          <section className="flex-1">
            {alteration && (
              <Button
                className="flex justify-between items-center mt-4 mb-8 w-full"
                onClick={handleNewAlteration}
                colored
              >
                <span className="flex-1 text-center">
                  {t('ADD_CHANGE', 'Adicionar alteração')}
                </span>

                <svg
                  width="14"
                  height="14"
                  transform="scale(-1 1)"
                  viewBox="0 0 12 20"
                  fill="none"
                >
                  <path
                    d="M11 1L2 10L3 11L11 19"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              </Button>
            )}

            <h3 className="mb-6 text-xl text-gray-500">
              {t('OBSERVATIONS', 'Observações')}
            </h3>

            {useDamages ? (
              room?.damages?.length ? (
                room?.damages?.map((damage, i) => (
                  <div
                    key={i}
                    className="flex flex-wrap gap-2 justify-between items-start mb-4 last-of-type:mb-6 sm:flex-nowrap"
                  >
                    <span className="flex-1 text-gray-500">
                      {damage?.description}
                    </span>

                    {inspection?.inspectionType ===
                      InspectionType.CONFERENCIA && (
                      <div className="flex gap-1 items-center">
                        <span className="text-gray-500">
                          {t('SITUATION', 'Situação')} -{' '}
                          {!damage?.resolved && t('NO', 'Não')}{' '}
                          {t('RESOLVED', 'Resolvido')}
                        </span>

                        <CircleStatus
                          color={damage?.resolved ? '#059669' : '#CF0000'}
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <span className="block mb-6 text-gray-500">
                  {t('NO_OBSERVATIONS_RECORDED', 'Nenhuma observação anotada')}
                </span>
              )
            ) : (
              <span className="block mb-6 text-gray-500">
                {room?.observation ||
                  t('NO_OBSERVATIONS_RECORDED', 'Nenhuma observação anotada')}
              </span>
            )}

            {inspection?.inspectionType !== 'predial' && (
              <>
                {status === Status.AVAILABLE ? (
                  <>
                    <div className="flex gap-2 items-center">
                      <CircleInfo color="#CF0000" className="shrink-0" />

                      <span className="text-error">
                        {t(
                          'SOMETHING_DIFFERENT',
                          'Algo diferente? Faça agora uma contestação'
                        )}
                      </span>
                    </div>

                    <Button
                      className="mt-2 mb-6 w-full max-w-sm"
                      styleType="danger"
                      onClick={handleNewDivergence}
                    >
                      {t('CONTEST', 'Contestar')}
                    </Button>
                  </>
                ) : status === Status.EXPIRED ? (
                  <div className="mb-4">
                    <div className="flex gap-2 items-center">
                      <CircleInfo color="#CF0000" className="shrink-0" />

                      <span className="text-error">
                        {t(
                          'CONTESTATION_EXPIRED',
                          'Prazo para contestação expirado'
                        )}
                      </span>
                    </div>

                    <div>
                      <span className="text-sm text-gray-500">
                        {t(
                          'CONTACT_ADMINISTRATOR',
                          'Entre em contato com a administradora para habilitar as contestações da vistoria novamente.'
                        )}
                      </span>
                    </div>
                  </div>
                ) : status === Status.UNAVAILABLE ? (
                  <div className="flex gap-2 items-center mb-4">
                    <CircleInfo color="#6b7280" className="shrink-0" />

                    <span className="text-gray-500">
                      {t(
                        'CONTESTATION_NOT_ALLOWED',
                        'Contestação não permitida no momento'
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center mb-4">
                    <CircleInfo color="#6b7280" className="shrink-0" />

                    <span className="text-gray-500">
                      {t(
                        'CONTESTATION_ALREADY_DONE',
                        'Contestação já realizada'
                      )}
                    </span>
                  </div>
                )}

                {!!divergences?.length && (
                  <div
                    onClick={handleGoToDivergences}
                    className="flex gap-x-2 justify-center items-center p-4 w-full text-primary bg-[#FAFAFA] rounded-xl border border-gray-300 shadow-sm cursor-pointer"
                  >
                    <span className="flex-1 text-center">
                      {t('SEE_CONTESTATIONS_AGAIN', 'Visualizar contestações')}
                    </span>

                    <img src={ArrowRight} alt="Arrow right icon" />
                  </div>
                )}
              </>
            )}
          </section>
        </div>

        <hr />

        <h3 className="mt-6 mb-4 text-lg font-bold text-gray-500">
          {t('NEXT_ITEMS_IN', 'Próximos itens em')}{' '}
          <span className="font-bold text-primary">{room?.name}</span>
        </h3>

        <div className="flex overflow-x-auto gap-6 mb-6 md:flex-wrap">
          {!!room?.items?.length &&
            room.items.map(item => (
              <RoomItem
                alteration={alteration}
                key={item?.id}
                id={item?.id}
                roomId={room?.id || ''}
                image={
                  useDamages ? item?.damagesPhotos[0]?.filePath : item?.image
                }
                name={item?.name}
                photosQuantity={
                  useDamages
                    ? item?.damagesPhotos?.length || 0
                    : item?.photos?.length || 0
                }
                state={item?.state}
                isCurrentItemInView={item?.id === 'general'}
                visitedByUser={!!item?.visitedByUser}
                small
              />
            ))}
        </div>
      </div>

      {showFullscreenViewer && (
        <FullscreenViewer
          startIndex={fullscreenViewerIndex}
          content={fullscreenViewerContent}
          onClose={handleCloseFullscreenViewer}
        />
      )}
    </div>
  )
}

export default GeneralRoomItemDetailsPage
