import React from 'react'

import { useTranslation } from 'react-i18next'

import X from '@/presentation/assets/svg/X.svg'
import Button from '@/presentation/components/Button'

interface DevicePermissionPopupProps {
  onCancel: () => void
}

const DevicePermissionPopup: React.FC<DevicePermissionPopupProps> = ({
  onCancel
}) => {
  const { t } = useTranslation()

  return (
    <div
      onClick={onCancel}
      className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]"
    >
      <div
        className="flex flex-col p-4 px-6 max-w-[460px] bg-white rounded-lg"
        onClick={event => event.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-1">
          <span className="text-lg text-gray-700">
            {t('ATTENTION', 'Atenção!')}
          </span>

          <img src={X} onClick={onCancel} className="cursor-pointer" />
        </div>

        <hr className="mb-4" />

        <span className="block mb-4 text-justify text-gray-700">
          {t(
            'BEFORE_SENDING_PHOTOS',
            'Antes de enviar as fotos da contestação certifique-se que o navegador (Safari, Google Chrome, Firefox etc) do seu dispositivo está com as devidas permissões para poder acessar ou tirar fotos.'
          )}
        </span>

        <Button className="mb-4 w-full" onClick={onCancel} colored>
          {t('UNDERSTOOD', 'Entendido')}
        </Button>
      </div>
    </div>
  )
}

export default DevicePermissionPopup
