import { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import Plus from '@/presentation/assets/svg/Plus.svg'
import TrashIcon from '@/presentation/assets/svg/TrashIcon.svg'
import Button from '@/presentation/components/Button'
import FormImage from '@/presentation/components/FormImage'
import Header from '@/presentation/components/Header'
import ConfirmDeleteAlterationPopup from '@/presentation/pages/Alteration/components/ConfirmDeleteAlterationPopup'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useAlteration } from '@/services/providers/AlterationContext'
import { usePreview } from '@/services/providers/PreviewContext'
import { useRoom } from '@/services/providers/RoomContext'
import validateFileType from '@/services/utils/validateFileType'

const AlterationRegisterPage: React.FC = () => {
  const [showConfirmDeleteAlterationPopup, setConfirmDeleteAlterationPopup] =
    useState(false)

  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()

  const { previews, setPreviews, thereIsAnImageBeingUploaded } = usePreview()
  const {
    pendingAlterations,
    alterationInProcess,
    makeAlteration,
    removeAlterationPhotoByFilePath,
    removeAlteration
  } = useAlteration()
  const { rooms } = useRoom()

  const imageInputRef = useRef<HTMLInputElement>(null)

  const [description, setDescription] = useState<string>(
    alterationInProcess?.description || ''
  )
  const [done, setDone] = useState<boolean>(alterationInProcess?.done || false)
  const [errors, setErrors] = useState<{ [key: string]: string }>()

  const room = rooms.find(room => room.id === alterationInProcess?.room_id)

  const item = room?.items.find(
    item => item.id === alterationInProcess?.item_id
  )

  const handleVerifyIfFileIsEmpty = (file: File) => {
    if (!file) return true

    return Object.keys(file).length === 0 && file.constructor === Object
  }

  const handleAddImage = () => imageInputRef.current?.click()

  const handleImageAdded = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files && files?.length > 0) {
      const [file] = files

      if (!validateFileType(file)) {
        setErrors(state => ({
          ...state,
          previews: t('INVALID_IMAGE', 'A imagem enviada é inválida!')
        }))

        return
      }

      setPreviews(state => [
        ...state,
        {
          id: uuidV4(),
          file,
          filePath: '',
          hasRemotelyUploaded: false,
          uploadProgress: 0
        }
      ])
    }
  }

  const handleRemoveImage = (
    indexToRemove: number,
    filePathToRemove?: string
  ) => {
    setPreviews(state => state.filter((_, index) => index !== indexToRemove))

    filePathToRemove && removeAlterationPhotoByFilePath(filePathToRemove)
  }

  const handleConfirmAlteration = async () => {
    setErrors({})

    const actualErrors: { [key: string]: string } = {}

    if (!description) {
      actualErrors.description = t(
        'DETAIL_CHANGE_TO_CONTINUE',
        'É necessário detalhar a alteração para continuar'
      )
    }

    if (Object.keys(actualErrors).length > 0) {
      setErrors(actualErrors)

      return
    }

    if (room && item) {
      const uploadPhotoError = await makeAlteration({
        room_id: room?.id,
        room_name: room?.name,
        item_id: item?.id,
        item_name: item?.name,
        description,
        done,
        photos: previews,
        solicitation_group: alterationInProcess?.solicitation_group
      })

      if (!uploadPhotoError) navigate(ROUTES.ALTERATION_PENDING_LIST)

      setErrors({
        previews: (actualErrors.previews = t(
          'ERROR_TO_UPLOAD_A_PHOTO',
          'Houve um erro ao enviar uma foto, tente novamente anexar as fotos'
        ))
      })
    }
  }

  const handleRemoveAlteratiion = () => {
    removeAlteration(room?.id as string, item?.id as string)

    navigate(ROUTES.ALTERATION_PENDING_LIST)
  }

  const handleOptionChange = (value: string) => {
    if (value === '1') {
      setDone(true)
    } else {
      setDone(false)
    }
  }

  useEffect(() => {
    if (!room) {
      navigate(ROUTES.MAIN)
    }

    if (!item) {
      navigate(ROUTES.ROOMS, true)
    }
  }, [item, navigate, room])

  useEffect(() => {
    setPreviews(
      alterationInProcess?.photos?.map(photo => ({
        ...photo,
        uploadProgress: 0
      })) || []
    )
  }, [])

  const isAlterationAlreadyRegistered = pendingAlterations.some(alteration => {
    return alteration?.room_id === room?.id && alteration?.item_id === item?.id
  })

  window.onbeforeunload = function () {
    return t(
      'SURE_WANT_TO_EXIT_CHANGES',
      'Você tem certeza que quer sair? Todo progresso de alterações será perdido!'
    )
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        title={t('REGISTER_CHANGES', 'Registrar alterações')}
        deleteDivergenceButton={
          isAlterationAlreadyRegistered && (
            <img
              className="w-5 cursor-pointer"
              src={TrashIcon}
              onClick={() => setConfirmDeleteAlterationPopup(true)}
            />
          )
        }
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <h1 className="mb-6 w-full text-3xl font-bold text-left text-primary">
          {t('NEW_CHANGE', 'Nova alteração')}
        </h1>

        <span className="block mb-2 w-full text-left text-gray-500">
          {t('SELECTED_ITEM', 'Item selecionado')}
        </span>

        <div className="box-border flex flex-col mb-4 w-full bg-white rounded-xl border border-gray-400 shadow-md">
          <div
            className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
            style={{ backgroundImage: `url(${item?.image})` }}
          />

          <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left rounded-b-xl border-t-0">
            <span className="text-xl font-semibold text-gray-600">
              {item?.id === 'building'
                ? item?.name
                : `${item?.name} | ${room?.name}`}
            </span>

            {item?.observation && (
              <span className="text-sm text-gray-500">{item?.observation}</span>
            )}
          </div>
        </div>

        <span className="block mt-4 mb-2 w-full text-left text-gray-500">
          {t('CHANGE_DONE', 'A alteração já foi realizada?')}
        </span>

        <div className="flex gap-6 justify-start items-center mb-4 w-full">
          <div className="flex flex-row gap-1 items-center">
            <input
              className="w-4 h-4 text-sm text-gray-800 rounded-md border-[1px] border-gray-300 focus-within:outline-primary cursor-pointer"
              type="radio"
              value="1"
              checked={done}
              onChange={v => handleOptionChange(v.currentTarget.value)}
            />

            <span className="ml-1">{t('YES', 'Sim')}</span>
          </div>

          <div className="flex flex-row gap-1 items-center">
            <input
              className="w-4 h-4 text-sm text-gray-800 rounded-md border-[1px] border-gray-300 focus-within:outline-primary cursor-pointer"
              type="radio"
              value="0"
              checked={!done}
              onChange={v => handleOptionChange(v.currentTarget.value)}
            />

            <span className="ml-1">{t('NO', 'Não')}</span>
          </div>
        </div>

        <span className="block mb-2 w-full text-left text-gray-500">
          {t('DESCRIBE_CHANGE', 'Descreva a alteração')}
        </span>

        <textarea
          className="block p-2 w-full min-h-[122px] text-sm rounded-lg border-[1.5px] border-gray-400 focus:border-primary focus:outline-none shadow-md"
          value={description}
          onChange={v => setDescription(v.currentTarget.value)}
        />

        <span className="block mt-1 w-full text-left text-error">
          {errors?.description}
        </span>

        <span className="block mt-4 mb-2 w-full text-left text-gray-500">
          {t('PHOTOS', 'Fotos')}
        </span>

        <div className="flex flex-wrap gap-4 justify-start items-center w-full">
          {previews.map((preview, index) => (
            <FormImage
              key={preview.filePath}
              image={
                !handleVerifyIfFileIsEmpty(preview?.file)
                  ? preview?.file
                  : preview?.filePath
              }
              uploadProgress={preview.uploadProgress}
              handleClickWithoutImage={handleAddImage}
              handleClickWithImage={() =>
                handleRemoveImage(index, preview?.filePath)
              }
              hasRemotelyUploaded={preview.hasRemotelyUploaded}
            />
          ))}

          {previews?.length < 5 && (
            <div
              onClick={handleAddImage}
              className="flex justify-center items-center w-[72px] h-[72px] bg-white rounded-lg border-[1.5px] border-gray-400 cursor-pointer"
            >
              <img src={Plus} />
            </div>
          )}
        </div>

        <input
          ref={imageInputRef}
          onChange={handleImageAdded}
          type="file"
          accept=".jpg, .jpeg, .bmp, .png, .gif"
          className="hidden"
        />

        <span className="block mt-1 w-full text-left text-error">
          {errors?.previews}
        </span>

        <Button
          className="mt-8 mb-4 w-full max-w-sm"
          onClick={handleConfirmAlteration}
          disabled={thereIsAnImageBeingUploaded}
        >
          {t('REGISTER_CHANGE', 'Registrar alteração')}
        </Button>
      </div>

      {showConfirmDeleteAlterationPopup && (
        <ConfirmDeleteAlterationPopup
          onCancel={() => setConfirmDeleteAlterationPopup(false)}
          onConfirmButton={() => handleRemoveAlteratiion()}
        />
      )}
    </div>
  )
}

export default AlterationRegisterPage
