import { FormEvent, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import OnboardingFirstPageBackground from '@/presentation/assets/img/OnboardingFirstPageBackground.jpg'
import Button from '@/presentation/components/Button'
import Input from '@/presentation/components/Input'
import { masks } from '@/presentation/constants'
import OnboardingBase from '@/presentation/containers/OnboardingBase'
import api from '@/services/api'
import { digitalInspectionStorageKey } from '@/services/constants/localStorageKeys'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useDivergence } from '@/services/providers/DivergenceContext'
import { useInspection } from '@/services/providers/InspectionContext'
import { useRoom } from '@/services/providers/RoomContext'
import { useUser } from '@/services/providers/UserContext'

type Errors = {
  name?: string
  cpf?: string
  phone?: string
  email?: string
  personType?: string
  terms?: string
}

interface RegisterPageProps {
  complete?: boolean
}

const RegisterPage: React.FC<RegisterPageProps> = ({ complete }) => {
  const navigate = useNavigateWithOrderCode()

  const { divergences } = useDivergence()
  const { rooms } = useRoom()
  const { inspection } = useInspection()
  const { user, setUser } = useUser()

  const [isSending, setIsSending] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [personType, setPersonType] = useState('')
  const [cpf, setCpf] = useState('')
  const [phone, setPhone] = useState('')

  const [errors, seErrors] = useState<Errors>({})

  const { orderCode } = useParams()

  const { t } = useTranslation()

  useEffect(() => {
    const userOnLocalStorage = sessionStorage.getItem(
      digitalInspectionStorageKey
    )

    if (userOnLocalStorage) {
      const userOnLocalStorageParsed = JSON.parse(userOnLocalStorage)

      setName(userOnLocalStorageParsed.name)
      setEmail(userOnLocalStorageParsed.email)
      setPersonType(userOnLocalStorageParsed.personType)
      setCpf(userOnLocalStorageParsed.cpf)
      setPhone(userOnLocalStorageParsed.phone)

      return
    }

    if (user) {
      setName(user.name)
      setEmail(user.email)
      setPersonType(user.personType)
    }
  }, [user])

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const newErrors: Errors = {}

    if (!name) {
      newErrors.name = t(
        'FILL_INFORMATION',
        'Preencha a informação para continuar'
      )
    }

    if (!email) {
      newErrors.email = t(
        'FILL_VALID_EMAIL',
        'Preencha um e-mail válido para continuar'
      )
    }

    if (!personType) {
      newErrors.personType = t(
        'SELECT_INFORMATION',
        'Selecione a informação para continuar'
      )
    }

    if (complete) {
      if (!cpf) {
        newErrors.cpf = t(
          'FILL_INFORMATION',
          'Preencha a informação para continuar'
        )
      }

      if (!phone) {
        newErrors.phone = t(
          'FILL_INFORMATION',
          'Preencha a informação para continuar'
        )
      }
    }

    if (Object.keys(newErrors).length > 0) {
      seErrors(newErrors)

      return
    }

    seErrors({})

    if (name && email && personType) {
      const payload = { name, email, personType, cpf, phone }

      setUser(payload)

      sessionStorage.setItem(
        digitalInspectionStorageKey,
        JSON.stringify(payload)
      )

      if (complete) {
        handleConfirmDivergence()
      } else {
        const responsible = {
          email: payload.email,
          name: payload.name,
          personType: payload.personType
        }
        api.post(`/public/hotsite/orders/${orderCode}/access`, {
          responsible
        })
        navigate(ROUTES.MAIN)
      }
    }
  }

  const handleConfirmDivergence = async () => {
    if (isSending) return

    try {
      setIsSending(true)

      const mappedDivergencesPayload = divergences
        .sort((divergence, nextDivergence) => {
          return (
            parseFloat(divergence?.roomId) - parseFloat(nextDivergence?.roomId)
          )
        })
        .reduce((acc, divergence) => {
          if (divergence?.itemId === 'building') {
            acc['0'] = {
              additionalData: {
                observation: divergence?.extra?.observation,
                damages: divergence?.extra?.damages,
                details: divergence?.extra?.details,
                photos: divergence?.extra?.photos,
                videos: divergence?.extra?.videos || []
              },
              description: divergence?.description,
              photos: divergence?.photos.map(photo => ({
                filePath: photo?.filePath
              }))
            }

            return acc
          }

          const itemRoom = rooms.find(room => room?.id === divergence?.roomId)

          const divergenceIdentifierKey =
            divergence?.itemId === 'general'
              ? divergence?.roomId
              : divergence?.itemId

          const name =
            divergence?.itemId === 'general'
              ? itemRoom?.name
              : divergence?.extra?.name

          acc[divergenceIdentifierKey] = {
            additionalData: {
              name,
              observation: divergence?.extra?.observation,
              damages: divergence?.extra?.damages,
              details: divergence?.extra?.details,
              photos: divergence?.extra?.photos,
              videos: divergence?.extra?.videos || []
            },
            description: divergence?.description,
            photos: divergence?.photos.map(photo => ({
              filePath: photo?.filePath
            }))
          }

          return acc
        }, {} as { [key: string]: any })

      if (!Object.keys(mappedDivergencesPayload).length) {
        toast.warning(
          t(
            'NO_CONTESTATION_REGISTERED',
            'Nenhuma contestação registrada! Registre uma contestação para continuar.'
          )
        )
        navigate(ROUTES.DIVERGENCE_LIST)
        return
      }

      const responsible = { cpf, email, name, phone, personType }

      sessionStorage.setItem(
        digitalInspectionStorageKey,
        JSON.stringify(responsible)
      )

      const payload = {
        responsible,
        divergences: { ...mappedDivergencesPayload, inspection }
      }

      await api.post(`/public/hotsite/${orderCode}`, payload)

      navigate(ROUTES.DIVERGENCE_SUCCESS)
    } catch {
      navigate(ROUTES.DIVERGENCE_ERROR)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <OnboardingBase
      showLogo
      Body={
        <form
          className="flex flex-col items-center  sm:w-96 md:w-120"
          autoComplete="on"
          onSubmit={handleSubmitForm}
        >
          {complete && (
            <span className="mb-10 text-[#72767D]">
              {t(
                'COMPLETE_REGISTRATION',
                'Para finalizar a solicitação é necessário preencher um breve cadastro para que possamos entrar em contato após nossa análise. Finalize o cadastro abaixo:'
              )}
            </span>
          )}

          <div className="mb-6 w-full">
            <Input
              name="name"
              placeholder={t('FULL_NAME', 'Nome completo')}
              value={name}
              onChange={event => setName(event.target.value)}
              errors={errors}
            />
          </div>

          {complete && (
            <>
              <div className="mb-6 w-full">
                <Input
                  name="cpf"
                  placeholder={
                    inspection?.country === 'PT' ? 'NIF' : t('CPF', 'CPF')
                  }
                  value={cpf}
                  onChange={event => setCpf(event.target.value)}
                  errors={errors}
                  mask={
                    inspection?.country === 'PT'
                      ? masks.CPF_ONLY_PT
                      : masks.CPF_ONLY_MASK
                  }
                  inputMode="numeric"
                />
              </div>

              <div className="mb-6 w-full">
                <Input
                  name="phone"
                  placeholder={t('PHONE', 'Telefone')}
                  value={phone}
                  onChange={event => setPhone(event.target.value)}
                  errors={errors}
                  mask={
                    inspection?.country === 'PT'
                      ? masks.CELLPHONE_MASK_PT
                      : masks.CELLPHONE_MASK
                  }
                  inputMode="numeric"
                />
              </div>
            </>
          )}

          <div className="mb-6 w-full">
            <Input
              name="email"
              placeholder={t('EMAIL', 'Email')}
              value={email}
              onChange={event => setEmail(event.target.value)}
              errors={errors}
              type="email"
              inputMode="email"
            />
          </div>

          <div className="flex flex-col gap-1 mb-20 w-full">
            <select
              className="p-2 pl-3 w-full text-[#495565] rounded-md border border-gray-300 focus-within:outline-none"
              value={personType}
              onChange={event => setPersonType(event.target.value)}
            >
              {inspection?.inspectionType === 'predial' ? (
                <>
                  <option className="hidden text-gray-500" value="" disabled>
                    {t(
                      'SYNDIC_CONDOMINUM_REALTY_ADMINISTRATOR_OR_OTHER',
                      'Síndico, Condômino, Imobiliária/Administrador ou Outro'
                    )}
                  </option>
                  <option value="Síndico">{t('SYNDIC', 'Síndico')}</option>
                  <option value="Condômino">
                    {t('CONDOMINUM_OWNER', 'Condômino')}
                  </option>
                  <option value="Imobiliária/Administrador">
                    {t('REALTY_ADMINISTRATOR', 'Imobiliária/Administrador')}
                  </option>
                  <option value="Outro">{t('OTHER', 'Outro')}</option>
                </>
              ) : (
                <>
                  <option className="hidden text-gray-500" value="" disabled>
                    {t(
                      'LESSOR_TENANT_REALTY',
                      'Locador, Locatário ou Imobiliária'
                    )}
                  </option>
                  <option value="Locador">
                    {t('LESSOR', 'Proprietário/Locador')}
                  </option>
                  <option value="Locatário">
                    {t('TENANT_AGAIN', 'Inquilino/Locatário')}
                  </option>
                  <option value="Imobiliária">
                    {t('REALTY_AGENCY', 'Imobiliária')}
                  </option>
                  <option value="Outro">{t('OTHER', 'Outro')}</option>
                </>
              )}
            </select>

            {errors?.personType && (
              <span className="text-error">{errors.personType}</span>
            )}
          </div>

          <Button className="mb-4 w-full max-w-sm" type="submit">
            {complete
              ? t(
                  'FINALIZE_REGISTRATION_AND_SEND',
                  'Finalizar cadastro e enviar'
                )
              : t('START_AGAIN', 'Iniciar')}
          </Button>
        </form>
      }
      BackgroundImage={OnboardingFirstPageBackground}
    />
  )
}

export default RegisterPage
