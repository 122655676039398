import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import { alterationOnboardingStorageKey } from '@/services/constants/localStorageKeys'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'

const AlterationOnboardingPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()
  const { orderCode }: { orderCode?: string } = useParams()

  const [terms, setTerms] = useState(false)
  const [errors, setErrors] = useState<{ terms: string }>()

  const handleToggleTerms = () => setTerms(!terms)

  const handleContinue = () => {
    if (!terms) {
      setErrors({
        terms: t(
          'NEED_TO_ACCEPT_TERMS',
          'para continuar você precisa aceitar os termos'
        )
      })

      return
    }

    if (orderCode) {
      localStorage.setItem(alterationOnboardingStorageKey(orderCode), 'true')
    }

    navigate(ROUTES.ROOMS, true)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title={t('REGISTER_CHANGES', 'Registrar alterações')} />

      <div className="flex flex-col items-center py-2 px-4 sm:w-96 md:w-144">
        <h1 className="mb-12 text-4xl font-bold text-primary">
          {t(
            'HOW_ALTERATION_REGISTRATION_WORKS',
            'Como funciona o Registro de alterações?'
          )}
        </h1>

        <ul className="block mb-8 ml-4 list-disc text-gray-500">
          <li>
            {t(
              'ALTERATION_REGISTRATION_CAN_BE_DONE',
              'O registro de alterações pode ser realizado'
            )}{' '}
            <span className="font-bold text-primary">
              {t('ANYTIME', 'a qualquer momento')}
            </span>
            ;
          </li>

          <br />

          <li>
            {t(
              'REGISTER_ALTERATIONS',
              'Você pode registrar alterações como: Reformas, manutenções e alterações de equipamento'
            )}
            ;
          </li>

          <br />

          <li>
            {t(
              'REGISTER_ALTERATIONS_PROPERTY_HISTORY',
              'O registro de alterações no imóvel deve ser realizado afim de integrar o histórico do imóvel, e assim'
            )}
            ,{' '}
            <span className="font-bold text-primary">
              {t('AVOID_NONCONFORMITIES', 'evitar não conformidades')}
            </span>{' '}
            {t('IN_EXIT_INSPECTION', 'desnecessárias na vistoria de saída')};
          </li>

          <br />

          <li>
            {t(
              'REGISTER_ONE_OR_MORE_CHANGES',
              'É possível registrar uma ou mais alterações em um único envio. Ao final de um registro clique em'
            )}{' '}
            <span className="font-bold text-primary">
              {t('NEW_CHANGE', 'Nova alteração')}
            </span>
            ;
          </li>

          <br />

          <li>
            {t('AFTER_CHANGE_SENT', 'Após enviado o registro de alteração')},{' '}
            <span className="font-bold text-primary">
              {t(
                'REALTY_AGENCY_WILL_BE_NOTIFIED',
                'a imobiliária será comunicada'
              )}
            </span>{' '}
            {t('FOR_ANALYSIS', 'para análise do registro')};
          </li>
        </ul>

        <div className="flex flex-col gap-1 mb-8 w-full">
          <div className="flex gap-2" onClick={handleToggleTerms}>
            <input
              checked={terms}
              type="checkbox"
              className="w-7"
              onChange={() => 'a'}
            />

            <span className="text-sm text-gray-500">
              {t(
                'ACCEPT_TERMS_OF_USE',
                'Ao aceitar os termos de uso, você declara estar de acordo com as orientações.'
              )}
            </span>
          </div>

          {errors?.terms && (
            <span className="text-red-600">{errors.terms}</span>
          )}
        </div>

        <Button className="mb-4 w-full max-w-sm" onClick={handleContinue}>
          {t('CONTINUE', 'Continuar')}
        </Button>
      </div>
    </div>
  )
}

export default AlterationOnboardingPage
