import api from '@/services/api'

type TEvaluatePayload = {
  rating: {
    inspector: number
    report: number
    comment?: string
  }
  creator: {
    name: string
    email: string
    personType: string
  }
}

const sendEvaluate = async (publicId: string, payload: TEvaluatePayload) => {
  try {
    await api.post(`/public/hotsite/orders/${publicId}/ratings`, payload)
  } catch (err) {
    console.error('sendEvaluate error ', err)
  }
}

export default sendEvaluate
