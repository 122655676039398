import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Footer from '@/presentation/components/Footer'
import AlterationErrorPage from '@/presentation/pages/Alteration/AlterationErrorPage'
import AlterationFinishRegisterPage from '@/presentation/pages/Alteration/AlterationFinishRegisterPage'
import AlterationItemDetailsPage from '@/presentation/pages/Alteration/AlterationItemDetailsPage'
import AlterationListPage from '@/presentation/pages/Alteration/AlterationListPage'
import AlterationOnboardingPage from '@/presentation/pages/Alteration/AlterationOnboardingPage'
import AlterationPendingListPage from '@/presentation/pages/Alteration/AlterationPendingListPage'
import AlterationRegisterPage from '@/presentation/pages/Alteration/AlterationRegisterPage'
import AlterationSuccessPage from '@/presentation/pages/Alteration/AlterationSuccessPage'
import BuildingDetailsPage from '@/presentation/pages/BuildingDetailsPage'
import DivergenceErrorPage from '@/presentation/pages/Divergence/DivergenceErrorPage'
import DivergenceOnboardingPage from '@/presentation/pages/Divergence/DivergenceOnboardingPage'
import DivergenceRegisterPage from '@/presentation/pages/Divergence/DivergenceRegisterPage'
import DivergenceSuccessPage from '@/presentation/pages/Divergence/DivergenceSuccessPage'
import DivergenceItemsListPage from '@/presentation/pages/DivergenceList/DivergenceItemsListPage'
import EvaluatePage from '@/presentation/pages/EvaluatePage'
import GeneralRoomItemDetailsPage from '@/presentation/pages/GeneralRoomItemDetailsPage'
import InspectionNotFoundPage from '@/presentation/pages/InspectionNotFoundPage'
import MainPage from '@/presentation/pages/MainPage'
import OnboardingPage from '@/presentation/pages/OnboardingPage'
import RegisterPage from '@/presentation/pages/RegisterPage'
import RoomItemDetailsPage from '@/presentation/pages/RoomItemDetailsPage'
import RoomsPage from '@/presentation/pages/RoomsPage'
import SentItemDetailsPage from '@/presentation/pages/SentItemDetailsPage'
import TutorialVideoPage from '@/presentation/pages/TutorialVideoPage'
import { ROUTES } from '@/services/constants/routes'
import LanguageCookieObserver from '@/services/LanguageCookieObserver'
import ProviderFactory from '@/services/ProviderFactory'
import TranslatorObserver from '@/services/TranslatorObserver'
import UserOnlyRoute from '@/services/UserOnlyRoute'

const publicRoutes = [
  {
    path: ROUTES.ONBOARDING,
    element: <OnboardingPage />
  },
  {
    path: ROUTES.REGISTER_INITIAL,
    element: <RegisterPage />
  }
]

const userOnlyRoutes = [
  {
    path: ROUTES.MAIN,
    element: <MainPage />
  },
  {
    path: ROUTES.EVALUATE,
    element: <EvaluatePage />
  },
  {
    path: ROUTES.ROOMS,
    element: <RoomsPage />
  },
  {
    path: ROUTES.ROOM_ITEM,
    element: <RoomItemDetailsPage />
  },
  {
    path: `${ROUTES.ROOM_ITEM}/:roomId/:itemId`,
    element: <RoomItemDetailsPage />
  },
  {
    path: `${ROUTES.ROOM_ITEM}/:roomId/general`,
    element: <GeneralRoomItemDetailsPage />
  },
  {
    path: `${ROUTES.ROOM_ITEM}/:roomId/building`,
    element: <BuildingDetailsPage />
  },
  {
    path: ROUTES.DIVERGENCE,
    element: <Navigate to={ROUTES.DIVERGENCE_ONBOARDING} />
  },
  {
    path: ROUTES.DIVERGENCE_TUTORIAL,
    element: <TutorialVideoPage />
  },
  {
    path: ROUTES.DIVERGENCE_ONBOARDING,
    element: <DivergenceOnboardingPage />
  },
  {
    path: ROUTES.DIVERGENCE_FORM,
    element: <DivergenceRegisterPage />
  },
  {
    path: ROUTES.DIVERGENCE_LIST,
    element: <DivergenceItemsListPage />
  },
  {
    path: ROUTES.REGISTER_COMPLETE,
    element: <RegisterPage complete />
  },
  {
    path: ROUTES.DIVERGENCE_SUCCESS,
    element: <DivergenceSuccessPage />
  },
  {
    path: ROUTES.DIVERGENCE_ERROR,
    element: <DivergenceErrorPage />
  },
  {
    path: `${ROUTES.SENT_ITEM}/:roomId/:itemId`,
    element: <SentItemDetailsPage />
  },
  {
    path: ROUTES.ALTERATION_LIST,
    element: <AlterationListPage />
  },
  {
    path: ROUTES.ALTERATION_ONBOARDING,
    element: <AlterationOnboardingPage />
  },
  {
    path: ROUTES.ALTERATION_FORM,
    element: <AlterationRegisterPage />
  },
  {
    path: ROUTES.ALTERATION_PENDING_LIST,
    element: <AlterationPendingListPage />
  },
  {
    path: ROUTES.ALTERATION_ERROR,
    element: <AlterationErrorPage />
  },
  {
    path: ROUTES.ALTERATION_REGISTER,
    element: <AlterationFinishRegisterPage />
  },
  {
    path: ROUTES.ALTERATION_SUCCESS,
    element: <AlterationSuccessPage />
  },
  {
    path: `${ROUTES.ALTERATION_SENT_ITEM}/:roomId/:itemId`,
    element: <AlterationItemDetailsPage />
  }
]

const Router: React.FC = () => (
  <div className="overflow-y-auto relative w-full h-full shadow-md">
    <main className="flex overflow-y-auto flex-col items-center min-h-screen">
      <ProviderFactory>
        <BrowserRouter>
          <TranslatorObserver />

          <LanguageCookieObserver />

          <Routes>
            <Route path=":orderCode">
              <Route index element={<Navigate to={ROUTES.ONBOARDING} />} />

              {publicRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}

              {userOnlyRoutes.map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={<UserOnlyRoute>{element}</UserOnlyRoute>}
                />
              ))}
            </Route>

            <Route
              path={ROUTES.NOT_FOUND}
              element={<InspectionNotFoundPage />}
            />

            <Route path="*" element={<InspectionNotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </ProviderFactory>
    </main>

    <Footer />
  </div>
)

export default Router
