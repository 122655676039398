type ProgressSpinnerProps = {
  progress: number
}

const ProgressSpinner: React.FC<ProgressSpinnerProps> = ({ progress }) => (
  <div className="relative">
    <svg
      className="-rotate-90"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="text-gray-400 dark:text-neutral-400 stroke-current"
        cx="18"
        cy="18"
        r="16"
        fill="none"
        strokeWidth="2"
      />

      <circle
        className="text-primary dark:text-primary stroke-current"
        cx="18"
        cy="18"
        r="16"
        fill="none"
        strokeWidth="2"
        strokeDasharray="100"
        strokeDashoffset={100 - progress}
        strokeLinecap="round"
      />
    </svg>
  </div>
)

export default ProgressSpinner
