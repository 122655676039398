import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import InspectionCardImage from '@/presentation/assets/img/Inspection.jpg'
import Calendar from '@/presentation/assets/svg/Calendar.svg'
import Button from '@/presentation/components/Button'
import { inspectionTypeToUI } from '@/presentation/mappers/InspectionTypeMapper'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useInspection } from '@/services/providers/InspectionContext'

interface InspectionCardProps {
  fetched: boolean
  displayDate?: string
}

const InspectionCard: React.FC<InspectionCardProps> = ({
  fetched,
  displayDate
}) => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()
  const { orderCode } = useParams()

  const { inspection } = useInspection()

  const handleClick = () => fetched && navigate(ROUTES.ROOMS)

  return (
    <div
      className="flex flex-col mb-6 w-full rounded-2xl shadow-md cursor-pointer"
      onClick={handleClick}
    >
      <img
        className="object-cover object-top h-80 rounded-t-2xl border border-b-0 border-gray-300"
        src={InspectionCardImage}
      />

      <div className="flex flex-col gap-4 p-4 rounded-b-2xl border border-t-0 border-gray-300">
        <div className="flex flex-col">
          <div className="flex gap-x-4 justify-between items-start">
            <div>
              <span className="block w-full text-xl font-bold text-gray-600">
                {inspectionTypeToUI(inspection?.inspectionType)}
              </span>

              {orderCode && (
                <span className="block mb-4 text-gray-600">{orderCode}</span>
              )}
            </div>

            <div className="flex gap-x-2 items-center">
              <img className="hidden sm:block" src={Calendar} alt="Calendar" />

              {displayDate && (
                <span className="text-gray-600">
                  {new Date(displayDate).toLocaleDateString('pt-BR')}
                </span>
              )}
            </div>
          </div>

          {inspection?.inspectionType !== 'predial' && (
            <span className="text-gray-600">
              {t(
                'CHECK_HERE_YOUR_DIGITAL_INSPECTION',
                'Confira aqui sua vistoria digital e'
              )}{' '}
              <span className="font-bold text-primary">
                {t('POINT_OUT_CONTESTATIONS', 'aponte contestações.')}
              </span>
            </span>
          )}
        </div>

        <Button className="py-4 text-lg">
          {t('ACCESS_DIGITAL_INSPECTION', 'Acessar vistoria digital')}
        </Button>
      </div>
    </div>
  )
}

export default InspectionCard
