import React from 'react'

import { CookiesProvider } from 'react-cookie'

import { AlterationProvider } from '@/services/providers/AlterationContext'
import { BuildingProvider } from '@/services/providers/BuildingContext'
import { DivergenceProvider } from '@/services/providers/DivergenceContext'
import { InspectionProvider } from '@/services/providers/InspectionContext'
import { PreviewProvider } from '@/services/providers/PreviewContext'
import { RoomProvider } from '@/services/providers/RoomContext'
import { UserProvider } from '@/services/providers/UserContext'

const providers = [
  PreviewProvider,
  AlterationProvider,
  BuildingProvider,
  DivergenceProvider,
  InspectionProvider,
  RoomProvider,
  UserProvider
]

interface ProviderFactoryProps {
  children: React.ReactElement
}

const ProviderFactory: React.FC<ProviderFactoryProps> = ({ children }) => (
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    {providers.reduceRight(
      (acc, Provider) => (
        <Provider>{acc}</Provider>
      ),
      children
    )}
  </CookiesProvider>
)

export default ProviderFactory
