import { useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'

import config from '@/config'
import MaximizeIcon from '@/presentation/assets/svg/Maximize.svg'
import MuteIcon from '@/presentation/assets/svg/MuteIcon.svg'
import PauseIcon from '@/presentation/assets/svg/PauseIcon.svg'
import PlayIcon from '@/presentation/assets/svg/PlayIcon.svg'
import UnmuteIcon from '@/presentation/assets/svg/UnmuteIcon.svg'
import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useVideoPlayer } from '@/services/hooks/useVideoPlayer'

import '@/presentation/pages/TutorialVideoPage/styles.css'

const TutorialVideoPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()

  const videoRef = useRef<HTMLVideoElement>(null)

  const {
    currentTime,
    duration,
    handleLoadingStart,
    handleLoadingEnd,
    handleTogglePlay,
    handleToggleSound,
    handleVideoProgress,
    handleVideoManualProgress,
    handleOpenFullscreen,
    loading,
    muted,
    playing,
    progress
  } = useVideoPlayer(videoRef, { autoPlay: true })

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title={t('CONTESTATIONS', 'Contestações')} />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <h1 className="mb-12 w-full text-4xl font-bold text-left text-primary">
          {t('HOW_TO_DO', 'Veja como fazer!')}
        </h1>

        <p className="mb-4 w-full text-left text-gray-500">
          {t(
            'WE_WOULD_LIKE_TO_HELP',
            'Gostaríamos de ajuda-lo a realizar o cadastro das informações da melhor forma.'
          )}
        </p>

        <p className="mb-4 w-full text-left text-gray-500">
          {t(
            'YOU_WILL_SEE_HOW_TO_REGISTER',
            'A seguir, você verá como realizar o cadastro de'
          )}{' '}
          <span className="font-bold text-primary">
            {t('ONE_OR_MORE_ITEMS', 'um ou mais itens')}
          </span>{' '}
          {t(
            'THAT_NEED_TO_BE_CONTESTED',
            'que necessitam ser contestados na vistoria. Confira o vídeo'
          )}
          :
        </p>

        <div
          id="tutorial-video-player"
          className="group relative mb-10 w-full rounded-2xl"
          onClick={event => event.stopPropagation()}
        >
          <video
            className="block w-full h-72 bg-black rounded-2xl sm:h-96"
            src={config.TUTORIAL_VIDEO_URL}
            ref={videoRef}
            onTimeUpdate={handleVideoProgress}
            onClick={handleTogglePlay}
            onLoadStart={handleLoadingStart}
            onLoadedData={handleLoadingEnd}
            muted={muted}
            loop
          />

          <BeatLoader
            className="!block absolute inset-y-1/2 inset-x-0 z-50 m-auto -mt-5 text-center lg:-mt-3"
            color="#ffffff"
            size={50}
            loading={loading}
          />

          <div className="flex absolute bottom-5 z-50 flex-col items-center px-4 w-full opacity-0 group-hover:opacity-100 transition-opacity ease-in">
            <div className="flex gap-x-4 justify-center items-center w-full">
              <button
                className="bg-none border-none outline-none cursor-pointer"
                type="button"
                disabled={loading}
                onClick={handleTogglePlay}
              >
                {!playing ? <img src={PlayIcon} /> : <img src={PauseIcon} />}
              </button>

              <input
                className="w-full h-1 rounded appearance-none cursor-pointer"
                type="range"
                min="0"
                max="100"
                value={progress}
                onChange={event => !loading && handleVideoManualProgress(event)}
              />

              <button
                className="bg-none border-none outline-none cursor-pointer"
                type="button"
                disabled={loading}
                onClick={handleToggleSound}
              >
                {!muted ? <img src={UnmuteIcon} /> : <img src={MuteIcon} />}
              </button>

              <button
                className="bg-none border-none outline-none cursor-pointer"
                type="button"
                disabled={loading}
                onClick={handleOpenFullscreen}
              >
                <img className="w-5 h-5" src={MaximizeIcon} />
              </button>
            </div>

            <div className="text-xs text-white">
              {currentTime.minutes}:{currentTime.seconds} / {duration.minutes}:
              {duration.seconds}
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-4 justify-between items-center mb-4 w-full sm:flex-nowrap">
          <Button
            className="w-full sm:flex-1"
            styleType="outline"
            onClick={() => history.back()}
            colored
          >
            {t('GO_BACK_TO_INSPECTION', 'Voltar para a vistoria')}
          </Button>

          <Button
            className="w-full sm:flex-1"
            onClick={() => navigate(ROUTES.DIVERGENCE_ONBOARDING)}
          >
            {t('CONTINUE', 'Continuar')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TutorialVideoPage
