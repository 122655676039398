type Photo = {
  filePath: string
  originalPhotoFilePath?: string
}

type Video = {
  filePath: string
  originalVideoFilePath?: string
}

type Damages = {
  damagePhotos: Photo[]
  damageVideos: Video[]
  description: string
  resolved: boolean
  type: string
  tags: string[]
}

export function formatDamagePhotos(
  damages: Damages[],
  inspectionPhotosPrefix: string
) {
  const mappedDamagesPhotos: Photo[] = []

  damages?.forEach(damage => {
    damage?.damagePhotos?.forEach(photo => {
      mappedDamagesPhotos.push({
        filePath: inspectionPhotosPrefix + photo?.filePath,
        originalPhotoFilePath: photo?.originalPhotoFilePath
          ? inspectionPhotosPrefix + photo?.originalPhotoFilePath
          : ''
      })
    })
  })

  return mappedDamagesPhotos
}

export function formatDamageVideos(
  damages: Damages[],
  inspectionVideosPrefix: string
) {
  const mappedDamagesVideos: Video[] = []

  damages?.forEach(damage => {
    damage?.damageVideos?.forEach(video => {
      mappedDamagesVideos.push({
        filePath: inspectionVideosPrefix + video?.filePath,
        originalVideoFilePath: video?.originalVideoFilePath
          ? inspectionVideosPrefix + video?.originalVideoFilePath
          : ''
      })
    })
  })

  return mappedDamagesVideos
}
