import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import AlterationStatusItem from '@/presentation/pages/Alteration/components/AlterationStatusItem'
import APROVATION_ITEM_STATUS from '@/services/constants/AprovationItemStatus'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import {
  Alteration,
  useAlteration
} from '@/services/providers/AlterationContext'
import { useRoom } from '@/services/providers/RoomContext'

const AlterationItemDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const [itemToShow, setItemToShow] = useState<Alteration>()
  const [itensToSelect, setItensToSelect] = useState<Alteration[]>([])

  const navigate = useNavigateWithOrderCode()
  const { startNewAlteration } = useAlteration()

  const { alterations } = useAlteration()
  const { rooms } = useRoom()

  const { roomId, itemId } = useParams()

  const room = rooms.find(room => room?.id === roomId)
  const item = room?.items.find(item => item?.id === itemId)
  const alterationItem = alterations.find(alteration => {
    return alteration?.room_id === roomId && alteration?.item_id === itemId
  })

  const changeItem = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const item = itensToSelect.find(
      i => Number(i.id) === Number(e.target.value)
    )
    setItemToShow(item)
  }

  useEffect(() => {
    if (!alterationItem || !item || !room) navigate(ROUTES.ALTERATION_LIST)
  }, [alterationItem, item, navigate, room])

  useEffect(() => {
    if (alterationItem?.related_solicitations?.length) {
      setItemToShow(alterationItem)
      const itens = [alterationItem, ...alterationItem.related_solicitations]
      setItensToSelect(itens)
    } else {
      setItemToShow(alterationItem)
    }
  }, [alterationItem])

  if (!itemToShow) return <></>

  const handleNewAlteration = (alterationItem: any) => {
    alterationItem?.room_id &&
      alterationItem?.item_id &&
      alterationItem?.solicitation_group &&
      startNewAlteration(
        alterationItem?.room_id,
        alterationItem?.item_id,
        alterationItem?.solicitation_group
      )

    navigate(ROUTES.ALTERATION_FORM)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={ROUTES.ALTERATION_LIST}
        title={`${item?.name} | ${room?.name}`}
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        {itensToSelect.length > 0 && (
          <div className="block mb-4 w-full">
            <p className="text-sm font-bold text-gray-700">
              {t('REQUEST_HISTORY', 'Histórico da solicitação')}
            </p>

            <select
              className="p-2 pl-3 w-full text-[#495565] rounded-md border border-gray-300 focus-within:outline-none"
              value={itemToShow.id}
              onChange={changeItem}
            >
              {itensToSelect?.map(item => (
                <option key={item.id} value={item.id}>
                  {dayjs(item.created_at).format('DD/MM/YYYY')}
                </option>
              ))}
            </select>
          </div>
        )}

        <span className="block mb-2 w-full font-bold text-left text-gray-500">
          {t('RESPONSIBLE_OPINION', 'Parecer do responsável')}
        </span>

        <AlterationStatusItem status={itemToShow?.status || ''} />

        {itemToShow?.status !== APROVATION_ITEM_STATUS.PENDING && (
          <>
            <span className="block mb-2 w-full font-bold text-left text-gray-500">
              {t(
                'NEED_TO_REVERT_CHANGES',
                'Será preciso reverter as alterações ao final da locação?'
              )}
            </span>

            <span className="mb-6 w-full text-sm text-left text-gray-500">
              {itemToShow?.revert_changes ? t('YES', 'Sim') : t('NO', 'Não')}
            </span>

            <span className="block mb-2 w-full font-bold text-left text-gray-500">
              {t('RESPONSIBLE_OBSERVATIONS', 'Observações do responsável')}
            </span>

            <span className="mb-6 w-full text-sm text-left text-gray-500">
              {itemToShow?.comment}
            </span>
          </>
        )}

        {itemToShow?.can_be_updated && itemToShow?.id === alterationItem?.id && (
          <Button
            className="flex justify-between items-center mt-4 mb-8 w-full max-w-sm"
            onClick={() => handleNewAlteration(itemToShow)}
            colored
          >
            <span className="flex-1 text-center">
              {t('UPDATE_REQUEST', 'Atualizar solicitação')}
            </span>

            <svg
              width="14"
              height="14"
              transform="scale(-1 1)"
              viewBox="0 0 12 20"
              fill="none"
            >
              <path d="M11 1L2 10L3 11L11 19" stroke="white" strokeWidth="2" />
            </svg>
          </Button>
        )}

        <p className="my-8 w-full border-b-2"></p>

        <span className="block mb-2 w-full font-bold text-left text-gray-500">
          {t('CHANGED_ITEM', 'Item alterado')}:
        </span>

        <div
          className="flex flex-col mb-6 w-full bg-white rounded-xl border border-gray-400 shadow-md cursor-pointer"
          onClick={() => navigate(`${ROUTES.ROOM_ITEM}/${roomId}/${itemId}`)}
        >
          <div
            className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
            style={{ backgroundImage: `url(${item?.image})` }}
          />

          <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left">
            <span className="text-xl font-semibold text-gray-600">
              {item?.id === 'building'
                ? item?.name
                : `${item?.name} | ${room?.name}`}
            </span>

            {item?.observation && (
              <span className="text-sm text-gray-500">{item?.observation}</span>
            )}
          </div>
        </div>

        <span className="block mb-2 w-full font-bold text-left text-gray-500">
          {t('CHANGE_ALREADY_DONE', 'A alteração já foi realizada?')}
        </span>

        <p className="mb-6 w-full text-sm text-left text-gray-500">
          {itemToShow?.done ? t('YES', 'Sim') : t('NO', 'Não')}
        </p>

        {itemToShow?.description && (
          <>
            <span className="block mb-2 w-full font-bold text-left text-gray-500">
              {t('DETAILS', 'Detalhes')}
            </span>

            <p className="mb-6 w-full text-sm text-left text-gray-500">
              {itemToShow.description}
            </p>
          </>
        )}

        {!!itemToShow?.photos?.length && (
          <>
            <span className="block mb-2 w-full text-left text-gray-500">
              {t('PHOTOS', 'Fotos')}
            </span>

            <div className="flex flex-wrap gap-4 justify-start items-center mb-6 w-full">
              {itemToShow.photos.map(photo => (
                <div
                  key={photo?.filePath}
                  className={`
                    relative
                    bg-no-repeat
                    bg-center
                    w-[72px]
                    h-[72px]
                    rounded-lg
                    border-[1.5px]
                    border-primary
                    ${photo?.filePath ? 'bg-cover' : 'bg-white'}
                  `}
                  style={{ backgroundImage: `url("${photo?.filePath}")` }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AlterationItemDetailsPage
