import React from 'react'

import ReactDOM from 'react-dom/client'

import '@material/react-linear-progress/dist/linear-progress.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'tailwindcss/tailwind.css'

import '@/infra/i18n/i18n'
import '@/index.css'

import App from '@/App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
