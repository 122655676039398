import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { getInspection } from '@/infra/http/getInspection'
import NewDivergenceSuccessIcon from '@/presentation/assets/svg/NewDivergenceSuccessIcon.svg'
import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import RatingPopup from '@/presentation/components/RatingPopup'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useBuilding } from '@/services/providers/BuildingContext'
import {
  ReviewStatus,
  useDivergence
} from '@/services/providers/DivergenceContext'
import { useInspection } from '@/services/providers/InspectionContext'
import { useRoom } from '@/services/providers/RoomContext'

const DivergenceSuccessPage: React.FC = () => {
  const [isRefetchingInspection, setIsRefetchingInspection] = useState(true)
  const [showRatingPopup, setShowRatingPopup] = useState(false)

  const { t } = useTranslation()

  const { setBuilding } = useBuilding()
  const { setImageUploadUrl, setStatus, setReviewStatus, setReports } =
    useDivergence()
  const { closeRatingInspectionPopupForever, inspection, setInspection } =
    useInspection()
  const { setRooms } = useRoom()

  const navigate = useNavigateWithOrderCode()
  const { orderCode } = useParams()

  const handleHomeClick = () => navigate(ROUTES.MAIN)

  const handleSeeDivergenceClick = () => navigate(ROUTES.DIVERGENCE_LIST)

  const handleGetInspectionData = async () => {
    if (!orderCode) {
      navigate(`/${ROUTES.NOT_FOUND}`)
      setIsRefetchingInspection(false)

      return
    }

    try {
      const data = await getInspection(orderCode)

      setImageUploadUrl(data?.imageUploadUrl || '')
      setInspection(data?.inspection || null)
      setReports(data?.reports || [])
      setStatus(data?.status || undefined)
      setReviewStatus(
        data?.reviewStatus || ReviewStatus.WAITING_FRANCHISEE_FEEDBACK
      )
      setBuilding(data?.building || null)
      setRooms(data.rooms || [])
    } catch {
      navigate(`/${ROUTES.NOT_FOUND}`)
    } finally {
      setIsRefetchingInspection(false)
    }
  }

  useEffect(() => {
    handleGetInspectionData()

    setTimeout(() => {
      setShowRatingPopup(true)
    }, 2000)
  }, [])

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title={t('CONTESTATIONS', 'Contestações')} />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[520px]">
        <h1 className="mb-4 w-full text-4xl font-bold text-left text-primary">
          {t('CONTESTATION_SENT', 'Contestação enviada! ')}
        </h1>

        <span className="block mb-10 w-full text-sm text-left text-gray-500">
          {t(
            'YOU_WILL_RECEIVE_A_RESPONSE_TO_YOUR_REQUEST',
            'Você receberá um retorno de sua solicitação em'
          )}{' '}
          <span className="font-bold text-primary">
            {t('WITHIN_5_WORKING_DAYS', 'até 5 dias úteis')}
          </span>
          .
        </span>

        <img
          className="mb-10"
          src={NewDivergenceSuccessIcon}
          alt="Mail box icon"
        />

        <span className="block mb-12 text-sm text-justify text-gray-500">
          {t(
            'YOU_CAN_FOLLOW_PROCESS_STATU',
            'É possível acompanhar o andamento do processo a qualquer hora pelo Meu Imóvel Digital usando o menu de'
          )}{' '}
          <span className="font-bold text-primary">
            {t('CONTESTATIONS', 'Contestações')}
          </span>
          .{' '}
          {t(
            'YOU_WILL_ALSO_RECEIVE_AN_EMAIL_NOTIFICATION',
            'Você também receberá uma notificação por e-mail assim que o relatório da contestação estiver disponível.'
          )}
        </span>

        <Button
          className="mb-4 w-full max-w-sm"
          onClick={handleSeeDivergenceClick}
          disabled={isRefetchingInspection}
        >
          {t('SEE_CONTESTATIONS', 'Ver contestações')}
        </Button>

        <Button
          className="mb-4 w-full max-w-sm"
          onClick={handleHomeClick}
          styleType="outline"
          disabled={isRefetchingInspection}
          colored
        >
          {t('RETURN_TO_START', 'Voltar ao início')}
        </Button>
      </div>

      {showRatingPopup &&
        !inspection?.wasRated &&
        !closeRatingInspectionPopupForever && (
          <RatingPopup
            onCancel={() => setShowRatingPopup(false)}
            redirectRouteAfterSubmit={ROUTES.MAIN}
          />
        )}
    </div>
  )
}

export default DivergenceSuccessPage
