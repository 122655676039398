import { useTranslation } from 'react-i18next'

import FinishedStatusIcon from '@/presentation/assets/svg/FinishedStatus.svg'
import ReviewStatusIcon from '@/presentation/assets/svg/ReviewStatus.svg'
import SentStatusIcon from '@/presentation/assets/svg/SentStatus.svg'
import {
  ReviewStatus,
  useDivergence
} from '@/services/providers/DivergenceContext'

const RequestStatus: React.FC = () => {
  const { t } = useTranslation()

  const { reviewStatus } = useDivergence()

  const sent = reviewStatus === ReviewStatus.WAITING_FRANCHISEE_FEEDBACK

  const underReview =
    reviewStatus === ReviewStatus.WAITING_CLIENT_FEEDBACK ||
    reviewStatus === ReviewStatus.WAITING_FRANCHISEE_AGREEMENT

  const finished = reviewStatus === ReviewStatus.DONE

  return (
    <div className="flex flex-col gap-4 items-start mb-6 w-full">
      <h2 className="block w-full text-lg text-left text-gray-500">
        {t('REQUEST_STATUS', 'Status da solicitação')}
      </h2>

      <div className="flex justify-between items-center w-full">
        <div className="flex flex-col gap-2 items-center text-primary">
          <img className="w-8 h-8" src={SentStatusIcon} alt="Arrow icon" />

          <h3> {t('SENT', 'Enviada')}</h3>

          <div className="flex justify-center items-center w-10 h-10 rounded-full border-2 border-primary">
            {sent && <div className="w-5 h-5 bg-primary rounded-full" />}
          </div>
        </div>

        <div className="flex-1 p-px mt-[72px] mr-[-10px] ml-[-14px] bg-primary" />

        <div className="flex flex-col gap-2 items-center text-primary">
          <img
            className={`
              w-8
              h-8
              ${sent && 'opacity-60'}
            `}
            src={ReviewStatusIcon}
            alt="Arrow icon"
          />

          <h3 className={sent ? 'opacity-60' : ''}>
            {t('ANALYSIS', 'Análise')}
          </h3>

          <div className="flex justify-center items-center w-10 h-10 rounded-full border-2 border-primary">
            {underReview && <div className="w-5 h-5 bg-primary rounded-full" />}
          </div>
        </div>

        <div className="flex-1 p-px mt-[72px] mr-[-22px] ml-[-10px] bg-primary" />

        <div className="flex flex-col gap-2 items-center text-primary">
          <img
            className={`
              w-8
              h-8
              ${(sent || underReview) && 'opacity-60'}
            `}
            src={FinishedStatusIcon}
            alt="Arrow icon"
          />

          <h3 className={sent || underReview ? 'opacity-60' : ''}>
            {t('FINALIZED', 'Finalizado')}
          </h3>

          <div className="flex justify-center items-center w-10 h-10 rounded-full border-2 border-primary">
            {finished && <div className="w-5 h-5 bg-primary rounded-full" />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestStatus
