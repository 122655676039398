import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import EditIcon from '@/presentation/assets/svg/Edit'
import TimeIcon from '@/presentation/assets/svg/Time'
import Header from '@/presentation/components/Header'
import Aprovation from '@/presentation/pages/Alteration/components/Aprovation'
import Historic from '@/presentation/pages/Alteration/components/Historic'
import { ROUTES } from '@/services/constants/routes'

const AlterationListPage: React.FC = () => {
  const [tabSelected, setTabSelected] = useState('Aprovações')

  const { t } = useTranslation()

  const handleSelectTab = (tab: string) => {
    setTabSelected(tab)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header previousRoute={ROUTES.MAIN} title="Registrar alterações" />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        {tabSelected === 'Aprovações' && (
          <>
            <h1 className="mb-6 w-full text-[28px] font-bold text-left text-primary">
              {t('CHANGES_AGAIN', 'Alterações')}
            </h1>

            <p className="mb-4 w-full text-left text-gray-500">
              {t(
                'CHECK_ADMIN_RESPONSE',
                'Consulte o retorno da administração e realize o registro de novas alterações no imóvel.'
              )}
            </p>
          </>
        )}

        {tabSelected === 'Histórico' && (
          <>
            <h1 className="mb-6 w-full text-[28px] font-bold text-left text-primary">
              {t('CHANGE_HISTORY', 'Histórico de alterações')}
            </h1>

            <p className="mb-4 w-full text-left text-gray-500">
              {t(
                'TYPES_PHOTOS_DETAILS',
                'Consulte os tipos, fotos e detalhes de alterações realizadas no imóvel.'
              )}
            </p>
          </>
        )}

        <nav className="flex flex-wrap gap-6 items-center mb-6 border-b-primary">
          <button
            className={`flex flex-col font-bold justify-center items-center p-2 pb-1 ${
              tabSelected === 'Aprovações'
                ? 'text-primary border-b-4 border-b-primary'
                : 'text-gray-500 border-b-4 border-b-white'
            }`}
            onClick={() => handleSelectTab('Aprovações')}
          >
            <EditIcon
              color={tabSelected === 'Aprovações' ? '#0170B3' : '#858585'}
            />

            {t('CHANGES_AGAIN', 'Alterações')}
          </button>
          <button
            className={`flex flex-col font-bold justify-center items-center p-2 pb-1 ${
              tabSelected === 'Histórico'
                ? 'text-primary border-b-4 border-b-primary'
                : 'text-gray-500 border-b-4 border-b-white'
            }`}
            onClick={() => handleSelectTab('Histórico')}
          >
            <TimeIcon
              color={tabSelected === 'Histórico' ? '#0170B3' : '#858585'}
            />

            {t('HISTORIC', 'Histórico')}
          </button>
        </nav>

        {tabSelected === 'Aprovações' && <Aprovation />}

        {tabSelected === 'Histórico' && <Historic />}
      </div>
    </div>
  )
}

export default AlterationListPage
