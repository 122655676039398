import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import ArrowRight from '@/presentation/assets/svg/ArrowRight.svg'
import CircleInfo from '@/presentation/assets/svg/CircleInfo'
import KnowMoreModal from '@/presentation/pages/RoomsPage/components/KnowMorePopup'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { Status, useDivergence } from '@/services/providers/DivergenceContext'

const Footer: React.FC = () => {
  const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)

  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()

  const { divergences, status } = useDivergence()

  const handleGoToDivergences = () => {
    navigate(ROUTES.DIVERGENCE_LIST)
  }

  return (
    <div className="flex flex-col justify-start items-start mb-8 w-full">
      <span className="font-bold text-gray-500">
        {t('CONTESTATIONS', 'Contestações')}
      </span>

      {divergences.length > 0 ? (
        <div
          onClick={handleGoToDivergences}
          className="flex gap-x-2 justify-center items-center p-4 mt-2 w-full max-w-sm text-primary bg-[#FAFAFA] rounded-xl border border-gray-300 shadow-sm cursor-pointer"
        >
          <span className="flex-1 text-center">
            {t('SEE_CONTESTATIONS_AGAIN', 'Visualizar contestações')}
          </span>

          <img src={ArrowRight} alt="Arrow right icon" />
        </div>
      ) : status === Status.EXPIRED ? (
        <div className="mt-2">
          <div className="flex gap-2 items-center ">
            <CircleInfo color="#CF0000" className="shrink-0" />

            <span className="text-error">
              {t('CONTESTATION_EXPIRED', 'Prazo para contestação expirado')}
            </span>
          </div>
          <div>
            <span className="text-sm text-gray-500">
              {t(
                'CONTACT_ADMINISTRATOR',
                'Entre em contato com a administradora para habilitar as contestações da vistoria novamente.'
              )}
            </span>
          </div>
        </div>
      ) : (
        <>
          <span className="mt-2 text-gray-500">
            {t(
              'NO_CONTESTATIONS_AT_THE_MOMENT',
              'Sem contestações no momento.'
            )}
          </span>

          <div className="flex gap-2 items-center">
            <CircleInfo
              className="cursor-pointer"
              onClick={() => setShowKnowMoreModal(true)}
            />

            <span
              className="text-primary cursor-pointer"
              onClick={() => setShowKnowMoreModal(true)}
            >
              {t('LEARN_MORE', 'Saiba mais')}
            </span>
          </div>
        </>
      )}

      {showKnowMoreModal && (
        <KnowMoreModal onCancel={() => setShowKnowMoreModal(false)} />
      )}
    </div>
  )
}

export default Footer
