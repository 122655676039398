import { useTranslation } from 'react-i18next'

import EvaluateImage from '@/presentation/assets/img/Evaluate.jpg'
import ArrowRight from '@/presentation/assets/svg/ArrowRight.svg'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'

const EvaluateCard: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()

  const handleClick = () => navigate(ROUTES.EVALUATE)

  return (
    <div
      onClick={handleClick}
      className="flex flex-col w-full h-full rounded-xl shadow-md cursor-pointer md:flex-1"
    >
      <img
        src={EvaluateImage}
        className="object-cover h-44 rounded-t-2xl border border-b-0 border-gray-300 md:h-24"
      />

      <div className="flex gap-1 justify-between items-center p-4 w-full rounded-lg rounded-t-none border border-t-0 border-gray-300">
        <div className="flex flex-col">
          <span className="text-lg font-bold text-gray-600">
            {t('EVALUATE_YOUR_INSPECTION', 'Avalie sua vistoria')}
          </span>

          <span className="text-sm text-gray-600">
            {t(
              'TELL_US_HOW_YOUR_EXPERIENCE_WITH_REDE_WAS',
              'Nos conte como foi sua experiência com a Rede!'
            )}
          </span>
        </div>

        <img src={ArrowRight} alt="Arrow right icon" />
      </div>
    </div>
  )
}

export default EvaluateCard
