import { useTranslation } from 'react-i18next'

import CircleCheck from '@/presentation/assets/svg/CircleCheck'
import CircleInfo from '@/presentation/assets/svg/CircleInfo'
import APROVATION_ITEM_STATUS from '@/services/constants/AprovationItemStatus'

interface AprovationStatusItemProps {
  status: string
}

const getColorTag = (status: string): JSX.Element => {
  const { t } = useTranslation()

  switch (status) {
    case APROVATION_ITEM_STATUS.PENDING:
      return (
        <span className="flex gap-1 items-center mb-6 w-full text-sm text-left text-[#0170B3]">
          <CircleInfo width={16} height={16} color={'#0170B3'} />

          {t('PENDING_APPROVAL', 'Aprovação pendente')}
        </span>
      )

    case APROVATION_ITEM_STATUS.APPROVED:
      return (
        <span className="flex gap-1 items-center mb-6 w-full text-sm text-left text-[#059669]">
          <CircleCheck width={16} height={16} color={'#059669'} />

          {t('CHANGE_APPROVED', 'Alteração aprovada')}
        </span>
      )

    case APROVATION_ITEM_STATUS.PARTIALLY_APPROVED:
      return (
        <span className="flex gap-1 items-center mb-6 w-full text-sm text-left text-[#eab308]">
          <CircleInfo width={16} height={16} color={'#eab308'} />

          {t('PARTIALLY_APPROVED', 'Parcialmente aprovada')}
        </span>
      )

    case APROVATION_ITEM_STATUS.REJECTED:
      return (
        <span className="flex gap-1 items-center mb-6 w-full text-sm text-left text-[#ef4444]">
          <CircleInfo width={16} height={16} color={'#ef4444'} />

          {t('APPROVAL_REJECTED', 'Aprovação rejeitada')}
        </span>
      )

    default:
      return <></>
  }
}

const AlterationStatusItem = ({
  status
}: AprovationStatusItemProps): JSX.Element => {
  return <>{getColorTag(status)}</>
}

export default AlterationStatusItem
