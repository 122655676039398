import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import KnowMoreModal from '@/presentation/pages/RoomsPage/components/KnowMorePopup'
import { divergenceOnboardingStorageKey } from '@/services/constants/localStorageKeys'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'

const DivergenceOnboardingPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()
  const { orderCode }: { orderCode?: string } = useParams()

  const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)
  const [terms, setTerms] = useState(false)
  const [errors, setErrors] = useState<{ terms: string }>()

  const handleToggleTerms = () => setTerms(!terms)

  const handleContinue = () => {
    if (!terms) {
      setErrors({
        terms: t(
          'NEED_TO_ACCEPT_TERMS',
          'para continuar você precisa aceitar os termos'
        )
      })

      return
    }

    if (orderCode) {
      localStorage.setItem(divergenceOnboardingStorageKey(orderCode), 'true')
    }

    navigate(ROUTES.DIVERGENCE_FORM)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title={t('POINT_CONTESTATION', 'Apontar contestação')} />

      <div className="flex flex-col items-center py-2 px-4 sm:w-96 md:w-144">
        <h1 className="mb-12 text-4xl font-bold text-primary">
          {t(
            'IMPORTANT_GUIDELINES',
            'Aqui vão algumas orientações importantes!'
          )}
        </h1>

        <ul className="block mb-8 ml-4 list-disc text-gray-500">
          <li>
            {t(
              'MAKE_SURE_CORRECT_ITEM',
              'Certifique-se de que sua observação está sendo'
            )}{' '}
            <span className="font-bold text-primary">
              {t('REGISTERED_IN_CORRECT_ITEM', 'registrada no item correto')}
            </span>
            ;
          </li>

          <br />

          <li>
            {t(
              'YOU_CAN_CONTEST_INSPECTION_ONCE',
              'Você poderá contestar a vistoria'
            )}{' '}
            <span className="font-bold text-primary">
              {t('ONLY_ONCE', 'somente uma vez')}
            </span>
            . {t('REGISTER_ALL_ITEMS', 'Registre')}{' '}
            <span className="font-bold text-primary">
              {t('ALL_ITEMS_TO_CONTEST', 'todos os itens que deseja contestar')}
            </span>{' '}
            {t('BEFORE_SENDING', 'antes de realizar o envio')};
          </li>

          <br />

          <li>
            {t(
              'NO_NEED_TO_CONTEST_IF_ALREADY_NOTED',
              'Caso suas observações sobre um item já se encontrem na vistoria, não há necessidade de contestá-lo.'
            )}{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => setShowKnowMoreModal(true)}
            >
              {t('LEARN_MORE', 'Saiba mais')};
            </span>
          </li>

          <br />

          <li>
            <span className="font-bold text-primary">
              {t('DESCRIBE_DETAILED', 'Descreva detalhadamente')}
            </span>{' '}
            {t(
              'ADD_PHOTOS_TO_CONTEST',
              'e insira fotos do item mostrando sua contestação'
            )}
            ;
          </li>

          <br />

          <li>
            {t(
              'AFTER_CONTESTATION_YOU_WILL_RECEIVE_A_RESPONSE',
              'Após a contestação você receberá um retorno da avaliação da Rede Vistorias em'
            )}{' '}
            <span className="font-bold text-primary">
              {t('WITHIN_5_WORKING_DAYS', 'até 5 dias úteis')}
            </span>
            ;
          </li>
        </ul>

        <div className="flex flex-col gap-1 mb-8 w-full">
          <div className="flex gap-2" onClick={handleToggleTerms}>
            <input
              checked={terms}
              type="checkbox"
              className="w-7"
              onChange={() => 'a'}
            />

            <span className="text-sm text-gray-500">
              {t(
                'BY_ACCEPTING_TERMS_OF_USE_YOU_AGREE',
                'Ao aceitar os termos de uso, você declara estar de acordo com as orientações.'
              )}
            </span>
          </div>

          {errors?.terms && (
            <span className="text-red-600">{errors.terms}</span>
          )}
        </div>

        <Button className="mb-4 w-full max-w-sm" onClick={handleContinue}>
          {t('CONTINUE', 'Continuar')}
        </Button>
      </div>

      {showKnowMoreModal && (
        <KnowMoreModal onCancel={() => setShowKnowMoreModal(false)} />
      )}
    </div>
  )
}

export default DivergenceOnboardingPage
