import api from '@/services/api'
import { Building } from '@/services/providers/BuildingContext'
import { Item, Room } from '@/services/providers/RoomContext'
import {
  formatDamagePhotos,
  formatDamageVideos
} from '@/services/utils/formatDamagePhotosAndVideos'

export const getInspection = async (orderCode: string) => {
  const { data } = await api.get(`/public/hotsite/${orderCode}`)

  const inspectionPhotosPrefix = (data?.inspection_photos_prefix || '') + '/'
  const inspectionVideosPrefix = (data?.inspection_videos_prefix || '') + '/'

  const building = data?.data?.building as Building

  const orderId = data?.data?.public_id
  const propertyId = data?.data?.property_public_id

  const serverRooms: Room[] = data?.data?.building?.rooms || []

  const mappedRooms = Object.entries(serverRooms).map(([id, obj], index) => ({
    ...obj,
    id,
    selected: index === 0,
    image:
      obj?.photos?.length && obj?.photos[0]?.filePath
        ? inspectionPhotosPrefix + obj?.photos[0]?.filePath
        : '',
    photos:
      obj?.photos?.map(photo => ({
        filePath: inspectionPhotosPrefix + photo?.filePath
      })) || [],
    damagesPhotos: formatDamagePhotos(
      obj?.damages || [],
      inspectionPhotosPrefix
    ),
    videos:
      obj?.videos?.map(video => ({
        filePath: inspectionVideosPrefix + video?.filePath
      })) || [],
    damagesVideos: formatDamageVideos(
      obj?.damages || [],
      inspectionVideosPrefix
    ),
    state:
      obj?.damages?.filter(
        damage =>
          !damage?.resolved &&
          damage?.type != 'general' &&
          (damage?.tags == null ||
            damage?.tags?.includes('Pendência do Inquilino'))
      ).length > 0
  }))

  const mappedRoomsWithMappedItems = mappedRooms.map(room => ({
    ...room,
    items: Object.entries(room.items).map(([id, obj]) => ({
      ...obj,
      id,
      image:
        obj?.photos?.length && obj?.photos[0]?.filePath
          ? inspectionPhotosPrefix + obj?.photos[0]?.filePath
          : room?.image,
      photos:
        obj?.photos?.map(photo => ({
          filePath: inspectionPhotosPrefix + photo?.filePath
        })) || [],
      damagesPhotos: formatDamagePhotos(
        obj?.damages || [],
        inspectionPhotosPrefix
      ),
      videos:
        obj?.videos?.map(video => ({
          filePath: inspectionVideosPrefix + video?.filePath
        })) || [],
      damagesVideos: formatDamageVideos(
        obj?.damages || [],
        inspectionVideosPrefix
      ),
      state:
        obj?.damages?.filter(
          damage =>
            !damage?.resolved &&
            damage?.type != 'general' &&
            (damage?.tags == null ||
              damage?.tags?.includes('Pendência do Inquilino'))
        ).length > 0
    }))
  }))

  const mappedRoomsWithGeneralItem = mappedRoomsWithMappedItems.map(room => {
    const generalRoomItem: Item = {
      damages: room?.damages || [],
      photos: room?.photos || [],
      damagesPhotos: room?.damagesPhotos || [],
      videos: room?.videos || [],
      damagesVideos: room?.damagesVideos || [],
      observation: room?.observation || '',
      details: room?.details || [],
      evaluation: room?.evaluation || {},
      id: 'general',
      image: room?.image,
      name: 'Geral',
      state: room?.state
    }

    return {
      ...room,
      items: [...(room?.items || []), generalRoomItem]
    }
  })

  const mappedBuilding = {
    ...building,
    observation: building?.observation || '',
    photos:
      building?.photos?.map(photo => ({
        filePath: inspectionPhotosPrefix + photo?.filePath
      })) || [],
    damagesPhotos: formatDamagePhotos(
      building.damages || [],
      inspectionPhotosPrefix
    ),
    videos:
      building?.videos?.map(video => ({
        filePath: inspectionVideosPrefix + video?.filePath
      })) || [],
    damagesVideos: formatDamageVideos(
      building.damages || [],
      inspectionVideosPrefix
    ),
    selected: false,
    state:
      building?.damages?.filter(
        damage =>
          !damage?.resolved &&
          damage?.type != 'general' &&
          (damage?.tags == null ||
            damage?.tags?.includes('Pendência do Inquilino'))
      ).length > 0
  }

  if (building) {
    const buildingRoom = {
      id: '0',
      selected: false,
      name: 'Geral',
      image: mappedBuilding?.photos?.length
        ? mappedBuilding?.photos[0]?.filePath
        : '',

      observation: mappedBuilding?.observation,
      details: mappedBuilding?.details || [],
      state: mappedBuilding?.state,
      damages: mappedBuilding?.damages,
      photos: mappedBuilding?.photos || [],
      damagesPhotos: mappedBuilding?.damagesPhotos || [],
      videos: mappedBuilding?.videos || [],
      damagesVideos: mappedBuilding?.damagesVideos || [],
      evaluation: {
        color: '',
        description: ''
      },
      items: [
        {
          id: 'building',
          image: mappedBuilding?.photos?.length
            ? mappedBuilding?.photos[0]?.filePath
            : '',
          evaluation: {
            color: '',
            description: ''
          },
          details: mappedBuilding?.details || [],
          damages: mappedBuilding?.damages,
          photos: mappedBuilding?.photos || [],
          damagesPhotos: mappedBuilding?.damagesPhotos || [],
          videos: mappedBuilding?.videos || [],
          damagesVideos: mappedBuilding?.damagesVideos || [],
          name: 'Geral',
          state: mappedBuilding?.state,
          observation: mappedBuilding?.observation || ''
        }
      ]
    }

    mappedRoomsWithGeneralItem.push(buildingRoom)
  }

  return {
    building: mappedBuilding,
    imageUploadUrl: data?.uploadPath,
    inspection: {
      people: data?.data?.people,
      address: data?.data?.address,
      identifier: data?.data?.identifier,
      displayDate: data?.data?.displayDate,
      inspectionType: data?.data?.inspectionType,
      publicId: data?.data?.public_id,
      wasRated: data?.data?.was_rated,
      canRegisterMaintenances: data?.data?.can_register_maintenances,
      country: data?.country
    },
    rooms: mappedRoomsWithGeneralItem,
    status: data?.status,
    reports: data?.reports,
    reviewStatus: data?.review_status,
    propertyId: propertyId,
    orderId: orderId
  }
}
