import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState
} from 'react'

import { visitedRoomItemsStorageKey } from '@/services/constants/localStorageKeys'

export type Photo = {
  filePath: string
  originalPhotoFilePath?: string
}

export type Video = {
  filePath: string
  originalVideoFilePath?: string
}

export type Damages = {
  damagePhotos: Photo[]
  damageVideos: Video[]
  description: string
  resolved: boolean
  type: string
  tags: string[]
}

export type Details = {
  label: string
  value: string
}

export type Evaluation = {
  color: string
  description: string
}

export type Item = {
  id: string | 'building' | 'general'
  image: string
  damagesPhotos: Photo[]
  photos: Photo[]
  damagesVideos: Video[]
  videos: Video[]
  name: string
  observation?: string
  state: boolean
  damages: Damages[]
  details: Details[]
  evaluation: Evaluation
  visitedByUser?: boolean
}

export type Room = {
  state: boolean
  id: string
  damages: Damages[]
  name: string
  selected: boolean
  items: Item[]
  observation?: string
  photos: Photo[]
  damagesPhotos: Photo[]
  videos: Video[]
  damagesVideos: Video[]
  details: Details[]
  evaluation: Evaluation
}

type StoredVisitedRoom = {
  [key: string]: string[]
}

type RoomContextStore = {
  allRoomsHaveBeenSelectedAtLeastOnce: boolean
  rooms: Room[]
  setRooms: Dispatch<SetStateAction<Room[]>>
  setSelectedRoomsIds: Dispatch<SetStateAction<Set<string>>>
  handleSelectRoom: (roomId: string) => void
  handlePutVisitedLabelInRoomItem: (
    orderCode: string,
    roomId: string,
    itemId: string
  ) => void
  getStoredVisitedRoomsItems: (orderCode: string, initialRooms: Room[]) => void
}

const RoomContext = createContext<RoomContextStore>({} as RoomContextStore)

RoomContext.displayName = 'RoomContext'

type RoomProviderProps = {
  children: React.ReactNode
}

export const RoomProvider: React.FC<RoomProviderProps> = ({ children }) => {
  const [rooms, setRooms] = useState<Room[]>([])
  const [selectedRoomsIds, setSelectedRoomsIds] = useState<Set<string>>(
    new Set(['1'])
  )

  const handleSelectRoom = useCallback((roomId: string) => {
    setSelectedRoomsIds(state => state.add(roomId))

    setRooms(state => {
      return state.map(room => ({
        ...room,
        selected: room.id === roomId
      }))
    })
  }, [])

  const handlePutVisitedLabelInRoomItem = useCallback(
    (orderCode: string, roomId: string, itemId: string) => {
      const roomsWithNewVisitedRoomItem = rooms.map(room => {
        if (room.id !== roomId) return room

        const visitedRoomIndex = room.items.findIndex(item => {
          return item.id === itemId
        })

        if (visitedRoomIndex !== -1) {
          room.items[visitedRoomIndex].visitedByUser = true
        }

        return room
      })

      const key = visitedRoomItemsStorageKey(orderCode)

      const roomsWithVisitedRoomItems = roomsWithNewVisitedRoomItem.map(
        room => ({
          [room?.id]: room?.items
            .filter(item => !!item?.visitedByUser)
            .map(item => item?.id)
        })
      )

      setRooms(roomsWithNewVisitedRoomItem)

      localStorage.setItem(key, JSON.stringify(roomsWithVisitedRoomItems))
    },
    [rooms]
  )

  const getStoredVisitedRoomsItems = useCallback(
    (orderCode: string, initialRooms: Room[]) => {
      if (!orderCode || !initialRooms?.length) return

      const key = visitedRoomItemsStorageKey(orderCode)
      const localStoredData = localStorage.getItem(key)
      const parsedLocalStoredData = JSON.parse(localStoredData || '[]') || []

      if (!parsedLocalStoredData?.length) return

      setRooms(state => {
        const roomsWithVisitedLabel = state.map(room => {
          const storedRoom = parsedLocalStoredData.find(
            (storedRoom: StoredVisitedRoom) => {
              const storedRoomId = Object.keys(storedRoom).at(0)

              return storedRoomId === room?.id
            }
          )

          if (!storedRoom) return room

          return {
            ...room,
            items: room?.items.map(item => ({
              ...item,
              visitedByUser: storedRoom[room?.id].includes(item?.id)
            }))
          }
        })

        return roomsWithVisitedLabel
      })
    },
    []
  )

  const allRoomsHaveBeenSelectedAtLeastOnce =
    selectedRoomsIds.size === rooms.length

  return (
    <RoomContext.Provider
      value={{
        allRoomsHaveBeenSelectedAtLeastOnce,
        rooms,
        setSelectedRoomsIds,
        setRooms,
        handleSelectRoom,
        handlePutVisitedLabelInRoomItem,
        getStoredVisitedRoomsItems
      }}
    >
      {children}
    </RoomContext.Provider>
  )
}

export const useRoom = () => {
  const context = useContext(RoomContext)

  if (!context) {
    throw new Error('useRoom must be used within an RoomProvider')
  }

  return context
}
