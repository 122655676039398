import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ptBr from '@/infra/i18n/translations/pt-BR.json'
import ptPt from '@/infra/i18n/translations/pt-PT.json'

i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      translation: ptBr
    },
    'pt-PT': {
      translation: ptPt
    }
  }
})
