import { useTranslation } from 'react-i18next'

import AlterationCard from '@/presentation/pages/MainPage/components/AlterationCard'
import DivergencesCard from '@/presentation/pages/MainPage/components/DivergencesCard'
import EvaluateCard from '@/presentation/pages/MainPage/components/EvaluateCard'
import InspectionCard from '@/presentation/pages/MainPage/components/InspectionCard'
import Title from '@/presentation/pages/MainPage/components/Title'
import UserAddress from '@/presentation/pages/MainPage/components/UserAddress'
import { useDivergence } from '@/services/providers/DivergenceContext'
import { useInspection } from '@/services/providers/InspectionContext'

const MainPage: React.FC = () => {
  const { t } = useTranslation()

  const { divergences } = useDivergence()
  const { inspection } = useInspection()

  return (
    <div className="flex flex-col py-6 px-4 w-full max-w-[842px]">
      <Title inspectionType={inspection?.inspectionType} />

      <UserAddress address={inspection?.address} />

      <InspectionCard
        fetched={!!inspection}
        displayDate={inspection?.displayDate}
      />

      <h2 className="mb-6 text-3xl font-bold text-primary">
        {t('SEE_ALSO', 'Veja também')}
      </h2>

      <div className="flex flex-wrap gap-4 justify-between items-start">
        {inspection?.canRegisterMaintenances && <AlterationCard />}

        <EvaluateCard />

        {inspection?.inspectionType !== 'predial' && !!divergences?.length && (
          <DivergencesCard />
        )}
      </div>
    </div>
  )
}

export default MainPage
