import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { getInspection } from '@/infra/http/getInspection'
import { getInspectionAlterations } from '@/infra/http/getInspectionAlterations'
import { getInspectionDivergences } from '@/infra/http/getInspectionDivergences'
import OnboardingFirstPageBackground from '@/presentation/assets/img/OnboardingFirstPageBackground.jpg'
import Button from '@/presentation/components/Button'
import ScreenLoading from '@/presentation/components/ScreenLoading'
import OnboardingBase from '@/presentation/containers/OnboardingBase'
import { digitalInspectionStorageKey } from '@/services/constants/localStorageKeys'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { useAlteration } from '@/services/providers/AlterationContext'
import { useBuilding } from '@/services/providers/BuildingContext'
import { useDivergence } from '@/services/providers/DivergenceContext'
import { useInspection } from '@/services/providers/InspectionContext'
import { useRoom } from '@/services/providers/RoomContext'
import { useUser } from '@/services/providers/UserContext'

const OnboardingPage: React.FC = () => {
  const [isFetchingInspection, setIsFetchingInspection] = useState(true)

  const { setBuilding } = useBuilding()
  const {
    getStoredDivergenceByOrderCode,
    setDivergences,
    setImageUploadUrl,
    setStatus,
    setReviewStatus,
    setReports
  } = useDivergence()

  const {
    getStoredPendingAlterationByOrderCode,
    setAlterations,
    setAlterationImageUploadUrl,
    setPropertyId,
    setOrderId
  } = useAlteration()
  const { inspection, setInspection } = useInspection()
  const { getStoredVisitedRoomsItems, setRooms } = useRoom()
  const { setUser } = useUser()

  const { orderCode } = useParams()
  const navigateWithDivergenceCode = useNavigateWithOrderCode()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleContinue = () => {
    const hasInformationOnLocalStorage = !!sessionStorage.getItem(
      digitalInspectionStorageKey
    )

    navigateWithDivergenceCode(
      hasInformationOnLocalStorage ? ROUTES.MAIN : ROUTES.REGISTER_INITIAL
    )
  }

  const handleGetInspectionData = async () => {
    if (!orderCode) {
      navigate(`/${ROUTES.NOT_FOUND}`)
      setIsFetchingInspection(false)

      return
    }

    try {
      const inspectionData = await getInspection(orderCode)
      const registeredDivergences = await getInspectionDivergences(orderCode)
      const registeredAlterations = await getInspectionAlterations(
        inspectionData?.orderId
      )

      setImageUploadUrl(inspectionData?.imageUploadUrl || '')
      setInspection(inspectionData?.inspection || null)
      setReports(inspectionData?.reports || [])
      setStatus(inspectionData?.status || undefined)
      setReviewStatus(inspectionData?.reviewStatus || undefined)
      setBuilding(inspectionData?.building || null)
      setRooms(inspectionData.rooms || [])
      setDivergences(registeredDivergences)
      getStoredDivergenceByOrderCode(orderCode, inspectionData?.status)
      getStoredVisitedRoomsItems(orderCode, inspectionData.rooms || [])

      setAlterationImageUploadUrl(inspectionData?.imageUploadUrl || '')
      setAlterations(registeredAlterations)
      setPropertyId(inspectionData?.propertyId || '')
      setOrderId(inspectionData?.orderId || '')
      getStoredPendingAlterationByOrderCode(orderCode)
    } catch {
      navigate(`/${ROUTES.NOT_FOUND}`)
    } finally {
      setIsFetchingInspection(false)
    }
  }

  useEffect(() => {
    handleGetInspectionData()
  }, [])

  useEffect(() => {
    const user = sessionStorage.getItem(digitalInspectionStorageKey)

    if (!user) return

    setUser(JSON.parse(user))
  }, [])

  if (isFetchingInspection) {
    return <ScreenLoading />
  }

  return (
    <OnboardingBase
      showLogo
      Body={
        <div className="flex flex-col items-center sm:w-96 md:w-120">
          <h1 className="mb-6 w-full text-4xl font-bold text-left text-primary md:text-5xl">
            {inspection?.inspectionType === 'predial'
              ? t('MY_DIGITAL_CONDOMINIUM', 'Meu condomínio digital')
              : t('MY_DIGITAL_PROPERTY', 'Meu imóvel digital')}
          </h1>

          <span className="mb-20 w-full font-medium text-left text-gray-900">
            {t(
              'HERE_YOU_CAN_SEE_YOUR_INSPECTION_DIGITALLY',
              'Aqui você poderá ver sua vistoria de forma totalmente digital e interativa, registrar uma contestação e avaliar sua experiência com a Rede Vistorias.'
            )}
          </span>

          <span className="w-full text-sm font-medium text-left text-gray-900">
            {t(
              'BEFORE_PROCEEDING_MAKE_SURE',
              'Antes de prosseguir, certifique-se de estar utilizando uma conexão estável com a internet e um navegador atualizado.'
            )}
          </span>
        </div>
      }
      Button={
        <Button className="my-4 w-72" onClick={handleContinue}>
          {t('START_AGAIN', 'Iniciar')}
        </Button>
      }
      BackgroundImage={OnboardingFirstPageBackground}
    />
  )
}
export default OnboardingPage
