import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react'

type Preview = {
  readonly id: string
  readonly file: File
  readonly filePath: string
  readonly hasRemotelyUploaded: boolean
  readonly uploadProgress: number
}

type ProviderContextStore = {
  previews: Preview[]
  setPreviews: Dispatch<SetStateAction<Preview[]>>
  thereIsAnImageBeingUploaded: boolean
}

const PreviewContext = createContext<ProviderContextStore>(
  {} as ProviderContextStore
)

PreviewContext.displayName = 'PreviewContext'

type PreviewProviderProps = {
  children: ReactNode
}

export const PreviewProvider: React.FC<PreviewProviderProps> = ({
  children
}) => {
  const [previews, setPreviews] = useState<Preview[]>([])

  const thereIsAnImageBeingUploaded = useMemo(
    () =>
      previews.some(
        preview => preview.uploadProgress > 0 && preview.uploadProgress < 100
      ),
    [previews]
  )

  return (
    <PreviewContext.Provider
      value={{ previews, setPreviews, thereIsAnImageBeingUploaded }}
    >
      {children}
    </PreviewContext.Provider>
  )
}

export const usePreview = () => {
  const context = useContext(PreviewContext)

  if (!context) {
    throw new Error('usePreview must be used within an PreviewProvider')
  }

  return context
}
