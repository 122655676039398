import Resizer from 'react-image-file-resizer'

const MAX_FILE_ALLOWED_SIZE = 1024

type Return = {
  resizedPhoto: File
  url?: string
}

export const resizePhoto = async (file: File): Promise<Return> => {
  try {
    const loadedImage = await new Promise<HTMLImageElement>(resolve => {
      const img = new Image()

      img.src = URL.createObjectURL(file)
      img.onload = () => resolve(img)
    })

    if (
      loadedImage.width < MAX_FILE_ALLOWED_SIZE ||
      loadedImage.height < MAX_FILE_ALLOWED_SIZE
    ) {
      return {
        resizedPhoto: file,
        url: loadedImage.src
      }
    }

    if (loadedImage.width > MAX_FILE_ALLOWED_SIZE) {
      const resizedPhoto = await new Promise<File>(resolve => {
        Resizer.imageFileResizer(
          file,
          MAX_FILE_ALLOWED_SIZE,
          loadedImage.height,
          'jpeg',
          90,
          0,
          resizedFile => {
            resolve(resizedFile as File)
          },
          'file'
        )
      })

      return {
        resizedPhoto,
        url: loadedImage.src
      }
    }

    const resizedPhoto = await new Promise<File>(resolve => {
      Resizer.imageFileResizer(
        file,
        loadedImage.width,
        MAX_FILE_ALLOWED_SIZE,
        'jpeg',
        90,
        0,
        resizedFile => {
          resolve(resizedFile as File)
        },
        'file'
      )
    })

    return {
      resizedPhoto,
      url: loadedImage.src
    }
  } catch {
    return {
      resizedPhoto: file
    }
  }
}
