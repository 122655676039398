import api from '@/services/api'
import { Alteration } from '@/services/providers/AlterationContext'

export const getInspectionAlterations = async (orderPublicId: string) => {
  try {
    const response = await api.get(
      `/maintenance/maintenances?order_public_id=${orderPublicId}&limit=100`
    )

    const alterations = response.data?.items.map((alteration: any) => {
      return {
        id: alteration?.id || '',
        item_id: alteration?.item_id || '',
        room_id: alteration?.room_id || '',
        done: alteration?.done,
        created_at: alteration?.created_at || '',
        description: alteration?.description || '',
        photos: alteration?.photos || [],
        status: alteration?.status || '',
        comment: alteration?.comment || '',
        revert_changes: alteration?.revert_changes || '',
        can_be_updated: alteration?.can_be_updated || '',
        solicitation_group: alteration?.solicitation_group || '',
        related_solicitations: alteration?.related_solicitations || [],
        responsible_for_analysis: alteration?.responsible_for_analysis || '',
        updated_on: alteration?.updated_on || ''
      } as Alteration
    })

    return Array.isArray(alterations) ? alterations : []
  } catch {
    return []
  }
}
