import { useTranslation } from 'react-i18next'

import X from '@/presentation/assets/svg/X.svg'

interface KnowMoreModalProps {
  onCancel: () => void
}

const KnowMoreModal: React.FC<KnowMoreModalProps> = ({ onCancel }) => {
  const { t } = useTranslation()

  const handleClose = () => onCancel()

  return (
    <div
      onClick={handleClose}
      className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]"
    >
      <div
        className="flex flex-col p-4 px-6 max-w-[460px] bg-white rounded-lg"
        onClick={event => event.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-1">
          <span className="text-lg text-gray-700">
            {t('CONTESTATION_MENU', 'Contestação')}
          </span>

          <img src={X} onClick={handleClose} className="cursor-pointer" />
        </div>

        <hr className="mb-4" />

        <span className="mb-1 text-gray-700">
          <span className="font-bold text-primary">
            {t(
              'CONTESTATIONS_ARE_USED_TO_POINT_OUT_DIFFERENCES',
              'Contestações são utilizadas para apontar as diferenças'
            )}
          </span>{' '}
          {t(
            'BETWEEN_INSPECTION_AND_CURRENT_STATE',
            'entre a vistoria e o estado presente do imóvel.'
          )}
          <br />
          <span className="font-bold text-primary">
            {t(
              'IF_ANY_DETAIL_NOT_IN_YOUR_REPORT',
              'Caso seja detectado algum detalhe que não conste em seu relatório'
            )}
          </span>
          ,{' '}
          {t(
            'USE_CONTESTATION_OPTION',
            'utilize a opção de Contestação nas dependências e itens da vistoria.'
          )}
        </span>
      </div>
    </div>
  )
}

export default KnowMoreModal
