import React, { ReactNode, TouchEventHandler, useState } from 'react'

import { useTranslation } from 'react-i18next'

import ChangeImage from '@/presentation/assets/svg/ChangeImage.svg'
import {
  ArrowLeft,
  ArrowRight
} from '@/presentation/assets/svg/ImageViewerArrows'
import Minimize from '@/presentation/assets/svg/Minimize.svg'

interface FullscreenPhotoProps {
  addIndex: () => void
  onClose: () => void
  paginationCounter: ReactNode
  photoUrl: string
  showOriginalPhoto: boolean
  subtractIndex: () => void
  toggleOriginalPhoto: () => void
  originalPhotoFilePath?: string
}

const touchDiff = 8

const FullscreenPhoto: React.FC<FullscreenPhotoProps> = ({
  addIndex,
  onClose,
  paginationCounter,
  photoUrl,
  showOriginalPhoto,
  subtractIndex,
  toggleOriginalPhoto,
  originalPhotoFilePath
}) => {
  const [touchPosition, setTouchPosition] = useState<number | null>(null)

  const { t } = useTranslation()

  const handleTouchStart: TouchEventHandler<HTMLElement> = event => {
    const initialTouch = event.touches.item(0)

    setTouchPosition(initialTouch.clientX)
  }

  const handleTouchMove: TouchEventHandler<HTMLElement> = event => {
    if (!touchPosition) return

    const currentTouch = event.touches.item(0)
    const diff = touchPosition - currentTouch.clientX

    if (diff > touchDiff) addIndex()

    if (diff < -touchDiff) subtractIndex()

    setTouchPosition(null)
  }

  return (
    <div
      className="flex relative justify-center items-center max-h-screen"
      onClick={event => event.stopPropagation()}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <img className="w-full h-full max-h-screen rounded-2xl" src={photoUrl} />

      <div
        className="flex absolute flex-col justify-between p-1 w-full max-w-[1024px] h-full max-h-[768px]"
        onClick={event => event.stopPropagation()}
      >
        <img
          className="absolute top-4 right-4 cursor-pointer"
          src={Minimize}
          onClick={onClose}
        />

        {originalPhotoFilePath && (
          <img
            src={ChangeImage}
            onClick={toggleOriginalPhoto}
            className="flex absolute top-4 left-4 cursor-pointer"
            title={
              showOriginalPhoto
                ? t('SHOW_CURRENT_IMAGE', 'Mostrar imagem atual')
                : t('SHOW_ORIGINAL_IMAGE', 'Mostrar imagem original')
            }
          />
        )}

        <div className="absolute inset-y-1/2 left-4" onClick={subtractIndex}>
          <ArrowLeft />
        </div>

        <div className="absolute inset-y-1/2 right-4" onClick={addIndex}>
          <ArrowRight />
        </div>

        <div className="flex absolute inset-x-1/2 bottom-2 justify-center text-xs text-white sm:text-base">
          {paginationCounter}
        </div>
      </div>
    </div>
  )
}

export default FullscreenPhoto
