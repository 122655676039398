import { useTranslation } from 'react-i18next'

import SuccessModalIcon from '@/presentation/assets/svg/SuccessModalIcon.svg'

const SuccessModal: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]">
      <div className="flex flex-col gap-4 items-center p-8 bg-white rounded-lg shadow-md">
        <img src={SuccessModalIcon} className="w-24 h-24" />

        <span className="text-lg font-bold text-[#4F6066]">
          {t('EVALUATION_SENT', 'Avaliação enviada!')}
        </span>
      </div>
    </div>
  )
}

export default SuccessModal
