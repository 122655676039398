import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import PlaceHolder from '@/presentation/assets/img/PlaceHolder.png'
import ArrowRight from '@/presentation/assets/svg/ArrowRight.svg'
import CircleAttention from '@/presentation/assets/svg/CircleAttention.svg'
import CircleCheck from '@/presentation/assets/svg/CircleCheck.svg'
import Eye from '@/presentation/assets/svg/Eye.svg'
import ImageIcon from '@/presentation/assets/svg/Image.svg'
import MapMark from '@/presentation/assets/svg/MapMark.svg'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'

interface RoomItemProps {
  id: string
  image: string
  roomId: string
  name: string
  small: boolean
  photosQuantity: number
  state: boolean
  isCurrentItemInView?: boolean
  visitedByUser?: boolean
  alteration: boolean
}

const RoomItem: React.FC<RoomItemProps> = ({
  id,
  image,
  roomId,
  name,
  small,
  photosQuantity,
  state,
  isCurrentItemInView,
  visitedByUser,
  alteration
}) => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()

  const [imageLoaded, setImageLoaded] = useState(false)

  const handleItemClick = () => {
    switch (id) {
      case 'building':
        navigate(`${ROUTES.ROOM_ITEM}/0/building`, alteration)
        break

      case 'general':
        navigate(`${ROUTES.ROOM_ITEM}/${roomId}/general`, alteration)
        break

      default:
        navigate(`${ROUTES.ROOM_ITEM}/${roomId}/${id}`, alteration)
    }
  }

  useEffect(() => {
    const preLoadImage = () => {
      if (!image) return
      const img = new Image()
      img.src = image
      img.onload = () => {
        setImageLoaded(true)
      }
    }
    if (!imageLoaded) {
      preLoadImage()
    }
  }, [imageLoaded, image])

  return (
    <div
      className={`
        relative
        flex
        overflow-hidden
        flex-col
        shrink-0
        w-[300px]
        shadow-md
        cursor-pointer
        border
        border-gray-400
        max-w-[${small ? 240 : 320}px]
        ${small ? 'rounded-lg' : 'rounded-xl'}
      `}
      onClick={handleItemClick}
    >
      {isCurrentItemInView ? (
        <div className="flex absolute top-0 left-0 gap-x-2 justify-center items-center p-1 w-full bg-primary">
          <img src={MapMark} />

          <span className="text-sm text-white">
            {t('YOU_ARE_HERE', 'Você está aqui')}
          </span>
        </div>
      ) : (
        !!visitedByUser && (
          <div className="flex absolute top-0 left-0 flex-col gap-y-px justify-center items-center w-full h-[80px] bg-black opacity-40">
            <img src={Eye} />

            <span className="text-base text-white">{t('SEEN', 'Visto')}</span>
          </div>
        )
      )}

      {image && imageLoaded ? (
        <LazyLoadImage
          className="w-full h-20 bg-center bg-no-repeat bg-cover"
          src={image}
          alt={image}
          width="100%"
        />
      ) : (
        <div className="w-full h-20">
          <img src={PlaceHolder} />
        </div>
      )}

      <div
        className={`
          h-full
          flex
          flex-col
          py-2
          rounded-t-none
          ${small ? 'p-2 rounded-lg' : 'p-4 rounded-xl'}
        `}
      >
        <div className="flex justify-between">
          <span className="text-lg font-bold text-gray-600">{name || ''}</span>

          <img className="h-[30px]" src={ArrowRight} />
        </div>

        <div className={`flex gap-2 items-center`}>
          <img src={ImageIcon} />

          <span className="text-gray-500">
            {photosQuantity || 0} {t('PHOTOS', 'fotos')}
          </span>
        </div>

        <div className={`flex gap-1 items-center ${!small && 'mt-1'}`}>
          {state ? (
            <>
              <img src={CircleAttention} />

              <span className="text-error">
                {t('WITH_OBSERVATIONS', 'Com observações')}
              </span>
            </>
          ) : (
            <>
              <img src={CircleCheck} />

              <span className="text-success">
                {t('WITHOUT_OBSERVATIONS', 'Sem observações')}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RoomItem
