export const divergenceOnboardingStorageKey = (orderCode: string) =>
  `@rvhotsite:divergence-onboarding-checked:${orderCode}`

export const alterationOnboardingStorageKey = (orderCode: string) =>
  `@rvhotsite:alteration-onboarding-checked:${orderCode}`

export const languageStorageKey = '@rvhotsite:user-portuguese-language'

export const divergencesStorageKey = (orderCode: string) =>
  `@rvhotsite:divergences:${orderCode}`

export const visitedRoomItemsStorageKey = (orderCode: string) =>
  `@rvhotsite:visited-room-items:${orderCode}`

export const digitalInspectionStorageKey =
  '@rvhotsite:digital-inspection-responsible'

export const alterationsStorageKey = (orderCode: string) =>
  `@rvhotsite:alterations:${orderCode}`

export const permissionPopupStorageKey =
  '@rvhotsite:permission-popup-already-seen'
