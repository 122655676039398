import { Fragment, useEffect } from 'react'

import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

import { languageStorageKey } from '@/services/constants/localStorageKeys'
import { useInspection } from '@/services/providers/InspectionContext'

const languagesToDisableI18n = ['en', 'es']

const countryToLanguage = new Map([
  ['BR', 'pt-BR'],
  ['PT', 'pt-PT']
])

const LanguageCookieObserver: React.FC = () => {
  const [cookies] = useCookies(['googtrans'])
  const { i18n } = useTranslation()

  const { inspection } = useInspection()

  useEffect(() => {
    if (typeof cookies.googtrans === 'string') {
      const currentLanguage = cookies.googtrans.split('/').at(-1)

      if (currentLanguage && languagesToDisableI18n.includes(currentLanguage)) {
        i18n.changeLanguage('NONE') // disables i18n translations by passing an unknown language string & triggers fallback messages
      }
    }
  }, [cookies.googtrans])

  useEffect(() => {
    const handleStorageChange = () => {
      const storedLanguage = localStorage.getItem(languageStorageKey)

      i18n.changeLanguage(
        storedLanguage || countryToLanguage.get(inspection?.country || 'BR')
      )
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [inspection])

  return <Fragment />
}

export default LanguageCookieObserver
