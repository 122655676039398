import { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AlterationEmpty from '@/presentation/assets/svg/AlterationEmpty.svg'
import ArrowRight from '@/presentation/assets/svg/ArrowRight.svg'
import CircleInfo from '@/presentation/assets/svg/CircleInfo'
import Button from '@/presentation/components/Button'
import Item from '@/presentation/pages/Alteration/components/Item'
import APROVATION_ITEM_STATUS from '@/services/constants/AprovationItemStatus'
import { alterationOnboardingStorageKey } from '@/services/constants/localStorageKeys'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import {
  Alteration,
  useAlteration
} from '@/services/providers/AlterationContext'
import { useRoom } from '@/services/providers/RoomContext'
import checkLocalStorageKeyAndDo from '@/services/utils/checkLocalStorageKeyAndDo'

const Aprovations: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigateWithOrderCode()
  const { orderCode } = useParams()
  const { pendingAlterations } = useAlteration()

  const [itemsWithRoom, setItemsWithRoom] = useState<Alteration[]>([])

  const { alterations } = useAlteration()
  const { rooms } = useRoom()

  const mappedAlterationItems = useMemo(() => {
    if (!itemsWithRoom?.length) return []

    const pendingItems: any[] = []
    itemsWithRoom.map(alteration => {
      const room = rooms.find(room => room?.id === alteration?.room_id)
      const item = room?.items.find(item => item?.id === alteration?.item_id)

      if (alteration.status === APROVATION_ITEM_STATUS.PENDING) {
        pendingItems.push({
          ...alteration,
          room,
          item
        })
      }
    })
    return pendingItems
  }, [itemsWithRoom])

  useEffect(() => {
    setItemsWithRoom(() => {
      return alterations.filter(alteration => !!alteration?.room_id)
    })
  }, [alterations, rooms])

  const handleNewAlteration = () => {
    if (
      orderCode &&
      checkLocalStorageKeyAndDo(alterationOnboardingStorageKey(orderCode))
    ) {
      navigate(ROUTES.ROOMS, true)

      return
    }
    navigate(ROUTES.ALTERATION_ONBOARDING)
  }

  return (
    <div className="flex flex-col justify-center items-center pb-8 w-full">
      <Button
        className="flex justify-between items-center mt-4 mb-8 w-full max-w-sm"
        onClick={handleNewAlteration}
        colored
      >
        <span className="flex-1 text-center">
          {t('NEW_CHANGE', 'Nova alteração')}
        </span>

        <svg
          width="14"
          height="14"
          transform="scale(-1 1)"
          viewBox="0 0 12 20"
          fill="none"
        >
          <path d="M11 1L2 10L3 11L11 19" stroke="white" strokeWidth="2" />
        </svg>
      </Button>

      {pendingAlterations.length > 0 && (
        <>
          <p className="flex items-center">
            <CircleInfo width={16} height={16} color={'#0170B3'} />
            <span className="ml-1 font-bold text-primary">
              {t('ATTENTION_AGAIN', 'Atenção')}
            </span>
            : {t('UNSENT_RECORDS', 'Você possui registros não enviados.')}
          </p>

          <Button
            styleType="outline"
            colored
            className="flex justify-between items-center mt-4 mb-8 w-full max-w-sm"
            onClick={() => navigate(ROUTES.ALTERATION_PENDING_LIST)}
          >
            <span className="flex-1 text-center">
              {t('SEE_PENDING_RECORDS', 'Ver registros pendentes')}
            </span>

            <img src={ArrowRight} />
          </Button>
        </>
      )}

      {mappedAlterationItems.length > 0 ? (
        <>
          <p className="my-4 w-full text-[18px]  font-bold text-left text-gray-500">
            {t('CHANGES_SENT', 'Alterações enviadas')}
          </p>

          {mappedAlterationItems.map((alteration, i) => (
            <Item key={i} alteration={alteration} />
          ))}
        </>
      ) : (
        <>
          <p className="my-4 text-gray-500">
            {t('NO_CHANGES_RECORDED', 'Não há alterações registradas')}
          </p>

          <img src={AlterationEmpty} />
        </>
      )}
    </div>
  )
}

export default Aprovations
