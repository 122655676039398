import { ToastContainer } from 'react-toastify'

import Router from '@/services/Router'

const App: React.FC = () => (
  <>
    <Router />

    <ToastContainer />
  </>
)

export default App
