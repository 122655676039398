import { useState } from 'react'

import { createPortal } from 'react-dom'

import FullscreenPhoto from '@/presentation/components/FullscreenViewer/FullscreenPhoto'
import FullscreenVideo from '@/presentation/components/FullscreenViewer/FullscreenVideo'
import { Photo, Video } from '@/services/providers/RoomContext'

type Content = Video | Photo

interface FullscreenViewerProps {
  startIndex: number
  content: Content[]
  onClose: () => void
}

const FullscreenViewer: React.FC<FullscreenViewerProps> = ({
  content,
  startIndex,
  onClose
}) => {
  const [actualIndex, setActualIndex] = useState(startIndex)
  const [showOriginalImage, setShowOriginalImage] = useState(false)

  const portalElement = document.getElementById('portal')
  const actualContent = content[actualIndex] as any

  const contentUrl =
    !actualContent?.isVideo &&
    actualContent?.originalPhotoFilePath &&
    showOriginalImage
      ? actualContent?.originalPhotoFilePath
      : actualContent?.filePath

  const addIndex = () => {
    if (actualIndex + 1 < content.length) {
      setActualIndex(actualIndex => actualIndex + 1)
      setShowOriginalImage(false)
    }
  }

  const subtractIndex = () => {
    if (actualIndex > 0) {
      setActualIndex(actualIndex => actualIndex - 1)
      setShowOriginalImage(false)
    }
  }

  if (!portalElement) return null

  return createPortal(
    <div
      className="flex overflow-y-auto fixed top-1/2 left-1/2 justify-center items-center
      p-5 w-full h-full min-h-full -translate-x-1/2 -translate-y-1/2 sm:p-11"
      onClick={onClose}
    >
      {!actualContent?.isVideo ? (
        <FullscreenPhoto
          addIndex={addIndex}
          onClose={onClose}
          paginationCounter={
            <>
              {actualIndex + 1}/{content.length}
            </>
          }
          photoUrl={contentUrl}
          showOriginalPhoto={showOriginalImage}
          subtractIndex={subtractIndex}
          toggleOriginalPhoto={() => setShowOriginalImage(state => !state)}
          originalPhotoFilePath={actualContent?.originalPhotoFilePath}
        />
      ) : (
        <FullscreenVideo
          addIndex={addIndex}
          onClose={onClose}
          paginationCounter={
            <>
              {actualIndex + 1}/{content.length}
            </>
          }
          subtractIndex={subtractIndex}
          videoUrl={contentUrl}
        />
      )}
    </div>,
    portalElement,
    'fullscreen-viewer-container'
  )
}

export default FullscreenViewer
