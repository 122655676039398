import { Navigate, useParams } from 'react-router-dom'

import { ROUTES } from '@/services/constants/routes'
import { useUser } from '@/services/providers/UserContext'

type UserOnlyRouteProps = {
  children: React.ReactElement
}

const UserOnlyRoute: React.FC<UserOnlyRouteProps> = ({ children }) => {
  const { user } = useUser()

  const { orderCode } = useParams()

  if (!user) {
    return <Navigate to={`/${orderCode}/${ROUTES.ONBOARDING}`} />
  }

  return children
}

export default UserOnlyRoute
